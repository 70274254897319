import PubSub from 'pubsub-js'


class CustomeEventBus {


    $on(eventName, callback) {
        return PubSub.subscribe(eventName, (eventName, data) => {
            callback(data)
        })
    }

    $off(eventName, callback) {
        PubSub.unsubscribe(eventName, callback)
    }

    $emit(eventName, data) {
        PubSub.publish(eventName, data);
    }
}

export const EventBus = new CustomeEventBus()
