<template>
  <div class="tree-select-wrapper">
    <label>{{ label }}</label>
    <treeselect
      ref="input"
      v-model="data"
      v-on="$listeners"
      :multiple="multiple"
      disable-fuzzy-matching
      :options="options"
      :default-expand-level="Infinity"
      :normalizer="normalizer"
      :placeholder="$t('treeselect.placeholder')"
      :search-prompt-text="$t('treeselect.type-to-search')"
      :no-results-text="$t('treeselect.no-results')"
      :loading-text	="$t('treeselect.loading')"
      :cache-options_off="false"
      :load-options_off="loadOptions"
      :async_off="true"
      :disabled="disabled"
      :clearable="clearable"
      :openDirection="openDirection"
      :appendToBody="appendToBody"
    >
      <template #value-label="{ node }">
        <v-icon left>{{ commonIcons().organization.type[node.raw.type] }}</v-icon>
        {{ formatLabel(node.raw.name) }}
        <div class="tooltip">
          {{ node.raw.path }}
        </div>
      </template>

      <template #option-label="{ node }">
        <v-icon left>{{ commonIcons().organization.type[node.raw.type] }}</v-icon>
        {{ formatLabel(node.raw.name) }}
      </template>
    </treeselect>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import { ASYNC_SEARCH, LOAD_ROOT_OPTIONS } from '@riophae/vue-treeselect'
import OrganizationRepository from "@repository/OrganizationRepository";

export default {
  components: {
    Treeselect
  },
  data() {
    return {
      data: this.value,
      options: [],
    }
  },

  model: {
    prop: 'value',
    event: 'input',
  },

  props: {
    value: [String,Array],
    label: String,
    multiple: Boolean,
    exclude: String,
    disabled: Boolean,
    clearable: Boolean,
    openDirection: {
      type: String,
      default: 'auto',
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
  },

  mounted() {
    if(!this.disabled) {
      if(this.$refs.input?.getInput()) {
        let input = this.$refs.input.getInput().$el.querySelector('input')
        input?.addEventListener('focus', this.onFocus)
        input?.addEventListener('blur', this.onBlur)
      }
    }
    this.refresh()
  },

  destroyed() {
    if(this.$refs.input?.getInput()) {
      let input = this.$refs.input.getInput().$el.querySelector('input')
      input?.removeEventListener('focus', this.onFocus)
      input?.removeEventListener('blur', this.onBlur)
    }
  },

  watch: {
    data(val) {
      if(val === undefined) {
        val = null
      }
      this.$emit('input', val)
    },

    value(val) {
      //this.loadSelected(val)
      this.data = val
    },

    exclude(val) {
      this.refresh()
    },

    disabled() {
      if(!this.disabled) {
        if(this.$refs.input?.getInput()) {
          let input = this.$refs.input.getInput().$el.querySelector('input')
          input?.removeEventListener('focus', this.onFocus)
          input?.removeEventListener('blur', this.onBlur)
          input?.addEventListener('focus', this.onFocus)
          input?.addEventListener('blur', this.onBlur)
        }
      }
    }
  },

  methods: {

    refresh() {
      this.loadData().then(options => {
        this.options =  options
        this.$emit('options-updated', options)
      })
    },

    loadData(search = '') {
      return OrganizationRepository.tree(search, this.exclude)
    },

    loadOptions({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH) {
        this.loadData(searchQuery).then(options => {
          callback(null, options)
        })
      }
    },

    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
        children: node.children,
      }
    },

    formatLabel(label) {
      if(label) {
        if(label.length < 35) {
          return label
        }
        return '...' + label.substr(-35)
      }
    },

    onFocus() {
      this.$emit('focus')
    },

    onBlur() {
      this.$emit('blur')
    },

    focus() {
      this.$refs.input.focusInput()
    },

    blur() {
      this.$refs.input.blurInput()
    }
  }
}
</script>

<style lang="scss">
@import "~@riophae/vue-treeselect/dist/vue-treeselect.css";

.tree-select-wrapper {
  & > label {
    font-family: dosis, sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    display: inline-block;
    position: absolute;
    transform: translateY(-5px) scale(0.75);
  }
}

.vue-treeselect {

  .vue-treeselect__menu-container {
    width: 600px;
  }
  &:not(.vue-treeselect--disabled):not(.vue-treeselect--focused) {
    .vue-treeselect__control {
      &:hover {
        border: 1px solid #758093;
      }
    }
  }
  &:not(.vue-treeselect--disabled):not(.vue-treeselect--focused) .vue-treeselect__control:hover {
    border-width: 0 0 1px 0;
  }
  .vue-treeselect__list-item {
    line-height: 50px;
    .vue-treeselect__option--selected {
        background-color: rgba(229,60,34,.16) !important;
        color: var(--v-primary-base)
    }
  }
  .vue-treeselect__menu {
    border: 1px solid #758093 !important;
    font-size: 18px;
    font-weight: 700;
  }
  .vue-treeselect__control {
    background-color: transparent;
    border-width: 0 0 1px 0;
    border-color: #75809340 !important;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    transition: .3s;
    min-height: 48px;

    .vue-treeselect__single-value, .vue-treeselect__multi-value-label {
      color: black !important;
      font-weight: 600;
    }

    .vue-treeselect__multi-value-item {
      background: transparent;
    }

    .vue-treeselect__value-remove {
      color: #ccc;
    }

    &:hover {
      border-width: 0 0 1px 0;
    }


    .vue-treeselect__control-arrow-container {
      width: 20px !important;
      .vue-treeselect__control-arrow {
        transition: .3s;
      }
    }

    .vue-treeselect__placeholder, .vue-treeselect__single-value, .vue-treeselect__multi-value-label {
      line-height: 48px;
      color: black;
      font-size: 16px;
      font-weight: 400;
    }

    .vue-treeselect__control-arrow-container {
      width: 38px;

      .vue-treeselect__control-arrow {
        color: #757575;
      }
    }

    .vue-treeselect__value-container {
      .vue-treeselect__single-value, .vue-treeselect__multi-value-label {
        .tooltip {
          display: none;
        }
      }
    }
  }
  &.vue-treeselect--disabled {
    .vue-treeselect__single-value, .vue-treeselect__multi-value-label {
      color: #b5b5b5 !important;
    }
  }

}
</style>
