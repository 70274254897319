import Vue from 'vue'

class OrganizationRepository {

  controller = null

  get(id, mini = false) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/organization/get/' + id + (mini ? '?mini' : ''),
        method: 'get',
      })
          .then(response => {
            resolve(response.data)
          }).catch(err => {
        reject(err)
      })
    })
  }

  cpoPrivateAccesses(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/organization/${id}/cpo-private-accesses`,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  addPrivateAccess(organizationId, customerId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/organization/cpo-private-access`,
        method: 'post',
        data: {
          organizationId,
          customerId,
        }
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  inviteCustomer(organizationId, customerId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/organization/invite-member`,
        method: 'post',
        data: {
          organizationId,
          customerId,
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  deletePrivateAccess(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/organization/cpo-private-access/${id}`,
        method: 'delete',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  inheritData(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/organization/get/' + id + '/inherit-data',
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  create(organization) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/organization',
        method: 'post',
        data: organization,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  update(id, organization) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/organization/' + id,
        method: 'put',
        data: organization,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  moveContent(id, data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/organization/move-content/' + id,
        method: 'post',
        data
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  tree(search = '', exclude = null) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/organization/tree',
        method: 'post',
        data: {
          search,
          exclude,
        },
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  search(data) {
    return new Promise((resolve, reject) => {

      if(this.controller) {
        this.controller.abort()
      }
      this.controller = new AbortController()

      Vue.auth.fetch({
        url: '/api/organization/search',
        method: 'post',
        data,
        signal: this.controller.signal
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  tabs(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/organization/${id}/tabs`,
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  viesVerify(vatNumber) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/organization/vies-verify`,
        method: 'post',
        data: {
          vatNumber
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default new OrganizationRepository()
