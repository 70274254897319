<template>
  <v-btn
      class="nav-btn"
      dark
      :outlined="!active"
      :color="active ? activeColor : normalColor"
      :to="to"
      :icon="icon"
      exact
      large
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  data() {
    return {
      normalColor: '#758093',
      activeColor: '#E53C22',
    }
  },

  props: {
    to: Object,
    icon: Boolean,
    matchPrefix: {type: Boolean, default: false},
  },


  computed: {
    active() {
      if(this.matchPrefix) {
        let $routeTo = this.$router.resolve(this.to).route
        return this.$route.path.substring(0, $routeTo.path.length) === $routeTo.path
      }
      return this.$route.name === this.to?.name
    }
  }
}
</script>

<style lang="scss">
  .nav-btn {
    letter-spacing: 0;
    &.v-btn--active {
      &:hover:before, &:before {
        opacity: 0 !important;
      }
    }
  }

</style>