
const MemberDetail = () => import(/* webpackChunkName: "member" */ "@pages/Member/MemberDetail.vue")
const MemberDetailInformation = () => import(/* webpackChunkName: "member" */ "@pages/Member/Detail/MemberDetailInformation.vue")
const MemberDetailForm = () => import(/* webpackChunkName: "member" */ "@pages/Member/Detail/MemberDetailForm.vue")
const MemberDetailChargingPoints = () => import(/* webpackChunkName: "member" */ "@pages/Member/Detail/MemberDetailChargingPoints.vue")
const MemberDetailGroups = () => import(/* webpackChunkName: "member" */ "@pages/Member/Detail/MemberDetailGroups.vue")
const MemberDetailUsages = () => import(/* webpackChunkName: "member" */ "@pages/Member/Detail/MemberDetailUsages.vue")
const MemberDetailTokens = () => import(/* webpackChunkName: "member" */ "@pages/Member/Detail/MemberDetailTokens.vue")

export default [
  {
    path: 'members/:memberId',
    component: MemberDetail,
    children: [
      {
        name: 'member',
        path: '',
        component: MemberDetailInformation,
      },
      {
        name: 'member.edit',
        path: 'edit',
        component: MemberDetailForm,
      },
      {
        name: 'member.groups',
        path: 'groups',
        component: MemberDetailGroups,
      },
      {
        name: 'member.charging-points',
        path: 'charging-points',
        component: MemberDetailChargingPoints,
      },
      {
        name: 'member.tokens',
        path: 'tokens',
        component: MemberDetailTokens,
      },
      {
        name: 'member.usages',
        path: 'usages',
        component: MemberDetailUsages,
      },
    ]
  },
]
