import Vue from 'vue'

class SecurityRepository {

  controller = null

  search(data) {
    return new Promise((resolve, reject) => {

      if(this.controller) {
        this.controller.abort()
      }
      this.controller = new AbortController()

      Vue.auth.fetch({
        url: '/api/security/user/search',
        method: 'post',
        data,
        signal: this.controller.signal
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  roles() {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/security/roles',
        method: 'get',
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  resetPassword(email) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/security/user/reset-password',
        method: 'post',
        data: {
          email
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  me() {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/security/user/me',
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  updateMe(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/security/user/me',
        method: 'POST',
        data
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  get(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/security/user/get/' + id,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }



  create(data) {
    return new Promise((resolve, reject) => {console.log(data)
      Vue.auth.fetch({
        url: '/api/security/user',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  update(id, data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/security/user/' + id,
        method: 'post',
        data
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  consolidateOrganization(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/security/user/consolidation',
        method: 'post',
        data
      })
          .then(response => {
            resolve(response.data)
          }).catch(err => {
        reject(err)
      })
    })
  }

}


export default new SecurityRepository()
