import Auth from './auth'


const VueJWT = {

    install(Vue, options) {

        let auth = new Auth(Vue, options);

        Vue.auth = auth

        Object.defineProperties(Vue.prototype, {
            $auth: {
                get: function () {
                    return auth
                }
            }
        });

    }
};

export default VueJWT;