<template>
  <v-autocomplete
      ref="input"
      v-model="data"
      :label="label"
      :loading="loading"
      :items="items"
      v-on="$listeners"
      :rules="rules"
      item-text="name"
      item-value="id"
      :solo="solo"
      :clearable="clearable"
      :append-icon="appendIcon"
      :readonly="readonly"
      :disabled="disabled"
      :placeholder="placeholder"
      hide-details
  ></v-autocomplete>
</template>

<script>

import ElectricityIndexRepository from "@repository/ElectricityIndexRepository";

export default {
  data(){
    return {
      loading: false,
      items: [],
      data: this.value,
    }
  },

  model: {
    prop: 'value',
    event: 'input',
  },

  props: {
    value: String,
    type: String,
    clearable: Boolean,
    readonly: Boolean,
    disabled: Boolean,
    solo: Boolean,
    rules: Array,
    appendIcon: String,
    placeholder: String,
    label: String,
  },

  watch: {
    data(val) {
      this.$emit('input', val)
    },

    value(val) {
      this.data = val
    }
  },

  mounted() {

    this.loadItems()
  },

  computed: {

  },

  methods: {
    loadItems() {
      let repository

      this.loading = true
      ElectricityIndexRepository.search(null, 0, 9999).then(result => {
        this.items = result.items
        this.loading = false
      }).catch(err => {
        this.$dialog.notify.error(err.data.message ?? err.message)
      })
    },

    focus() {
      this.$refs.input.focus()
    }
  }
}
</script>

<style lang="scss">

</style>