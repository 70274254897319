<template>
  <div class="title-page">
    <v-row dense class="align-end">
      <v-col>
        <slot name="content"></slot>
      </v-col>
      <v-col>
        <span>
          <slot></slot>
        </span>
        <v-icon size="80" v-if="icon">{{ icon }}</v-icon>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
  }
}
</script>

<style lang="scss">
  .title-page {
    &.overlap {
      position: relative;
      top: -40px;
    }
    border-bottom: 2px solid #758093;
    padding: 0 0 10px 0;
    margin: 0 0 20px 0;
    text-align: right;
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;
    color: #758093;
    span {
      position: relative;
      top: 10px;
    }
    .v-icon {
      margin-left: 20px;
      color: #758093;
    }
    //.v-breadcrumbs {
    //  margin: 0;
    //  padding: 0;
    //}

  }
</style>
