
const LocationSearch = () => import(/* webpackChunkName: "location" */ "@pages/Location/LocationSearch.vue")
const LocationDetail = () => import(/* webpackChunkName: "location" */ "@pages/Location/LocationDetail.vue")
const LocationDetailInformation = () => import(/* webpackChunkName: "location" */ "@pages/Location/Detail/LocationDetailInformation.vue")
const LocationDetailEdit = () => import(/* webpackChunkName: "location" */ "@pages/Location/Detail/LocationDetailEdit.vue")
const LocationDetailChargingPoints = () => import(/* webpackChunkName: "location" */ "@pages/Location/Detail/LocationDetailChargingPoints.vue")
const LocationDetailMeterDevices = () => import(/* webpackChunkName: "location" */ "@pages/Location/Detail/LocationDetailMeterDevices.vue")
const LocationDetailStatistics = () => import(/* webpackChunkName: "location" */ "@pages/Location/Detail/LocationDetailStatistics.vue")
const ChargingPointDetailUsages = () => import(/* webpackChunkName: "charging-point" */ "@pages/ChargingPoint/Detail/ChargingPointDetailUsages")

export default [
    {
        name: 'locations',
        path: 'locations',
        component: LocationSearch,
        meta: {
            auth: true,
        },
    },
    {
        path: 'location/:id',
        component: LocationDetail,
        children: [
            {
                name: 'location.detail',
                path: '',
                component: LocationDetailInformation,
            },
            {
                name: 'location.edit',
                path: 'edit',
                component: LocationDetailEdit,
            },
            {
                name: 'location.detail.charging-points',
                path: 'charging-points',
                component: LocationDetailChargingPoints,
            },
            {
                name: 'location.detail.meter-devices',
                path: 'meter-devices',
                component: LocationDetailMeterDevices,
            },
            {
                name: 'location.detail.usages',
                path: 'usages',
                component: ChargingPointDetailUsages,
            },
            {
                name: 'location.detail.statistics',
                path: 'statistics',
                component: LocationDetailStatistics,
            },
        ]
    },
]