import Vue from 'vue'
import VuetifyDialog from 'vuetify-dialog'
import vuetify from '@plugins/vuetify'
import i18n from './i18n.js'

Vue.use(VuetifyDialog, {
    context: {
        vuetify
    },
    confirm: {
        actions: {
            false: {
                text: i18n.t('no'),
                color: 'secondary'
            },
            true: {
                text: i18n.t('yes'),
                color: 'primary'
            }
        },
        icon: false, // to disable icon just put false
        width: 500
    },
    warning: {},
    error: {},
    prompt: {}
})