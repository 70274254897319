const OperatorGroupSearch = () => import(/* webpackChunkName: "roaming" */ "@pages/OperatorGroup/OperatorGroupSearch.vue")
const OperatorGroupAdd = () => import(/* webpackChunkName: "roaming" */ "@pages/OperatorGroup/OperatorGroupAdd.vue")
const OperatorGroupDetail = () => import(/* webpackChunkName: "roaming" */ "@pages/OperatorGroup/OperatorGroupDetail.vue")
const OperatorGroupDetailEdit = () => import(/* webpackChunkName: "roaming" */ "@pages/OperatorGroup/Detail/OperatorGroupDetailEdit.vue")
const OperatorGroupDetailInformation = () => import(/* webpackChunkName: "roaming" */ "@pages/OperatorGroup/Detail/OperatorGroupDetailInformation.vue")


export default [
    {
        name: 'operator-groups',
        path: 'operator-groups',
        component: OperatorGroupSearch,
        meta: {
            auth: true,
        },
    },

    {
        name: 'operator-group.add',
        path: 'operator-groups/add',
        component: OperatorGroupAdd,
        meta: {
            auth: true,
        },
    },

    {
        path: 'operator-groups/:id',
        component: OperatorGroupDetail,
        meta: {
            auth: true,
        },
        children: [
            {
                name: 'operator-group.detail',
                path: '',
                component: OperatorGroupDetailInformation,
            },
            {
                name: 'operator-group.edit',
                path: 'edit',
                component: OperatorGroupDetailEdit,
            }
        ]
    },
]

