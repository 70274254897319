<template>
  <v-text-field
      :label="label"
      ref="input"
      v-on="$listeners"
      v-model="data"
      :rules="rules"
      :solo="focus"
      @focus="onFocus"
      @blur="onBlur"
      :placeholder="placeholder"
      :prepend-icon="prependIcon"
      :prepend-inner-icon="prependInnerIcon"
      :append-icon="appendIcon"
      :append-outer-icon="appendOuterIcon"
      :type="type"
      class="textfield"
      :class="{'active' : focus}"
      :hide-details="hideDetails"
      :clearable="clearable"
  >
    <template #append>
      <slot name="append"></slot>
    </template>
  </v-text-field>
</template>

<script>
export default {
  data() {
    return {
      data: this.value,
      focus: null,
    }
  },

  model: {
    event: 'input',
    prop: 'value',
  },

  props: {
    value: {type: [String, Number] },
    rules: Array,
    type: String,
    solo: Boolean,
    hideDetails: {type: Boolean, default: true},
    label: String,
    prependIcon: String,
    prependInnerIcon: String,
    appendIcon: String,
    appendOuterIcon: String,
    placeholder: String,
    clearable: Boolean,
  },

  watch: {
    data(val) {
      this.$emit('input', val)
    },


    value(val) {
      this.data = val
    },
  },

  mounted() {
    this.removeAutofill();
    if(this.solo) {
      this.focus = true
    }
  },

  computed: {

    input() {
      return this.$refs.input
    }
  },

  methods: {
    onFocus() {
      if(!this.solo) {
        this.focus = true
      }
    },

    onBlur() {
      if(!this.solo) {
        this.focus = false
      }
    },

    removeAutofill() {
      const element = this.$el.querySelector('input')
      element.setAttribute('readonly', 'readonly');
      element.style.backgroundColor = 'inherit';

      setTimeout(() => {
        element.removeAttribute('readonly');
      }, 500);
    }
  }
}
</script>

<style lang="scss">
.textfield {
  &.active {
    .v-input__control {
      .v-input__slot {
      box-shadow: 0 10px 30px #00000029 !important;
        .v-text-field__slot {
          input {
            color: #E53C22 !important;
          }
        }
      }
    }
  }
  .v-input__control {
    .v-input__slot {
      .v-text-field__slot {
        input {
          color: #000 !important;
          font-weight: 600;
        }
      }
      &:before {
        border-color: #75809340 !important;
      }
    }
  }
}
</style>