<template>
  <div class="bloc-headline">
    <div class="headline-title" :class="{bg: bg, dotted: dotted}">
      <slot></slot>
    </div>
    <div v-if="!dotted" class="headline-shadow-line"></div>
  </div>
</template>

<script>
export default {
  props: {
    bg: Boolean,
    dotted: Boolean,
  }

}
</script>

<style lang="scss">
.bloc-headline {
  .headline-title {
    font-size: 22px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: var(--v-primary-base);
    text-transform: uppercase;
    position: relative;
    &:not(.dotted):after {
      content: '';
      position: absolute;
      background: #9a9a9a;
      background:
          linear-gradient(90deg,
              #9a9a9a00 0%,
              #9a9a9a00 5%,
              #9a9a9a 50%,
              #9a9a9a00 95%,
              #9a9a9a00 100%
          );
      bottom: 1px;
      left: 16px;
      right: 16px;
      height: 1px;
    }
    &.dotted:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      border-bottom: 1px #E53C22 dotted;
      bottom: 1px;
      height: 1px;
    }

    &.bg {
      background: #EFEFF0;
      border-radius: 10px 10px 0 0;
    }
    .right {
      position: absolute;
      right: 0;
    }
  }
  .headline-shadow-line {
    height: 25px;
    position: relative;
    overflow: hidden;
    &:after {
      content: "";
      position: absolute;
      top: -33px;
      left: 50%;
      width: 100%;
      height: 25px;
      max-width: 700px;
      transform: translateX(-50%);
      border-radius: 50%;
      margin: auto;
      box-shadow: 0px 0px 15px 15px rgb(217 215 215 / 65%);
    }
  }
}

</style>