import Vue from "vue";
import Sortable from 'sortablejs'
import Tools from "@includes/tools";

Vue.directive("sortable-data-table", {
    bind (el, binding, vnode) {
        const options = {
            animation: 150,
            onUpdate: function (event) {
                vnode.child.$emit('sorted', event)
            },
            handle: '.btn-sort'
        }
        Sortable.create(el.getElementsByTagName('tbody')[0], options)
    }
})

Vue.directive("uppercase", {
    update(el) {
        let input = el.querySelector('input');

        let sourceValue = input.value;
        const newValue = sourceValue.toUpperCase();

        if (sourceValue !== newValue) {
            input.value = newValue;
            input.dispatchEvent(new Event('input', { bubbles: true }));
        }
    },
})

Vue.directive("capitalize", {
    update(el) {
        let input = el.querySelector('input');

        let sourceValue = input.value;
        const newValue = Tools.capitaliserFirstLetter(sourceValue.toLowerCase());

        if (sourceValue !== newValue) {
            input.value = newValue;
            input.dispatchEvent(new Event('input', {bubbles: true}));
        }
    },
})