
const OrganizationSearch = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/OrganizationSearch.vue")
const OrganizationDetail = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/OrganizationDetail.vue")
const OrganizationAdd = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/OrganizationAdd.vue")
const OrganizationEdit = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/OrganizationEdit.vue")
const OrganizationInformation = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/OrganizationInformation.vue")
const OrganizationChildren = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/OrganizationChildren.vue")
const OrganizationTokens = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/OrganizationTokens.vue")
const OrganizationChargingPoints = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/OrganizationChargingPoints.vue")
const OrganizationCustomers = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/OrganizationCustomers.vue")
const OrganizationInvoices = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/OrganizationInvoices.vue")
const OrganizationMembers = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/OrganizationMembers.vue")

const MemberInvitationSearch = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/Member/MemberInvitationSearch.vue")
const MemberInvitationDetail = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/Invitation/MemberInvitationDetail.vue")

const MemberTypeSearch = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/Member/MemberTypeSearch.vue")
const MemberTypeForm = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/Member/MemberTypeForm.vue")

const MeterDeviceReadings = () => import(/* webpackChunkName: "price-index" */ "@pages/MeterDevice/Detail/MeterDeviceReadings.vue")

const MemberGroupSearch = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/Member/MemberGroupSearch.vue")
const MemberGroupForm = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/Member/MemberGroupForm.vue")
const OrganizationTariffs = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/OrganizationTariffs.vue")
const OrganizationUsages = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/OrganizationUsages.vue")
const OrganizationUsers = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/OrganizationUsers.vue")
const OrganizationSubscriptions = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/OrganizationSubscriptions.vue")
const OrganizationSubscriptionEdit = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/Subscription/OrganizationSubscriptionEdit.vue")
const OrganizationExpensePeriods = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/OrganizationExpensePeriods.vue")
const ExpensePeriodMembers = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/ExpenseRefund/ExpensePeriodMembers.vue")
const ExpensePeriodMember = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/ExpenseRefund/ExpensePeriodMember.vue")

const OrganizationSubscriptionLicences = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/Subscription/OrganizationSubscriptionLicences.vue")


const OrganizationEnergyProvider = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/OrganizationEnergyProvider.vue")

const EnergyProviderPeriod = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/EnergyProvider/EnergyProviderPeriod.vue")
const EnergyProviderPeriodLines = () => import(/* webpackChunkName: "organization" */ "@pages/Organization/Detail/EnergyProvider/EnergyProviderPeriodLines.vue")



export default [
  {
    name: 'organizations.search',
    path: 'organizations',
    component: OrganizationSearch,
    meta: {
      auth: true,
    },
  },

  {
    name: 'organizations.add',
    path: 'organizations/add',
    component: OrganizationAdd,
    meta: {
      auth: true,
    },
  },

  {
    path: 'organizations/:id',
    component: OrganizationDetail,
    children: [
      {
        path: '',
        name: 'organizations.detail',
        component: OrganizationInformation,

      },
      {
        name: 'organizations.edit',
        path: 'edit',
        component: OrganizationEdit,
      },
      {
        name: 'organizations.charging-points',
        path: 'charging-points',
        component: OrganizationChargingPoints,
      },

      {
        name: 'organizations.customers',
        path: 'customers',
        component: OrganizationCustomers,
      },
      {
        name: 'organizations.members',
        path: 'members',
        component: OrganizationMembers,
      },

      {
        name: 'organizations.members.invitations.manage',
        path: 'members/invitations/manage/:invitationId',
        component: MemberInvitationDetail,
      },

      {
        name: 'organizations.members.invitations',
        path: 'members/invitations',
        component: MemberInvitationSearch,
      },
      {
        name: 'organizations.members.types',
        path: 'members/types',
        component: MemberTypeSearch,
      },
      {
        name: 'organizations.members.types.add',
        path: 'members/types/add',
        component: MemberTypeForm,
      },
      {
        name: 'organizations.members.types.edit',
        path: 'members/types/:memberTypeId/edit',
        component: MemberTypeForm,
      },

      {
        name: 'organizations.members.groups',
        path: 'members/groups',
        component: MemberGroupSearch,
      },
      {
        name: 'organizations.members.groups.add',
        path: 'members/groups/add',
        component: MemberGroupForm,
      },
      {
        name: 'organizations.members.groups.edit',
        path: 'members/groups/:memberGroupId/edit',
        component: MemberGroupForm,
      },
      {
        name: 'organizations.tariffs',
        path: 'tariffs',
        component: OrganizationTariffs,
      },
      {
        name: 'organizations.invoices',
        path: 'invoices',
        component: OrganizationInvoices,
      },
      {
        name: 'organizations.usages',
        path: 'usages',
        component: OrganizationUsages,
      },
      {
        name: 'organizations.meter-devices-readings',
        path: 'meter-device-readings',
        component: MeterDeviceReadings,
      },
      {
        name: 'organizations.children',
        path: 'children',
        component: OrganizationChildren,
      },
      {
        name: 'organizations.tokens',
        path: 'tokens',
        component: OrganizationTokens,
      },
      {
        name: 'organizations.users',
        path: 'users',
        component: OrganizationUsers,
      },
      {
        name: 'organizations.subscriptions',
        path: 'subscriptions',
        component: OrganizationSubscriptions,
      },
      {
        name: 'organizations.subscriptions.edit',
        path: 'subscriptions/edit/:subscriptionId',
        component: OrganizationSubscriptionEdit,
      },
      {
        name: 'organizations.subscriptions.licences',
        path: 'subscriptions/:subscriptionId/licences',
        component: OrganizationSubscriptionLicences,
      },
      {
        name: 'organizations.expense-periods',
        path: 'expense-periods',
        component: OrganizationExpensePeriods,
      },
      {
        name: 'organizations.expense-periods.manage',
        path: 'expense-periods/:periodId',
        component: ExpensePeriodMembers,
      },
      {
        name: 'organizations.expense-periods.member',
        path: 'expense-periods/:periodId/:memberId',
        component: ExpensePeriodMember,
      },
      {
        name: 'organizations.energy-provider',
        path: 'recharge-service',
        component: OrganizationEnergyProvider,
      },
      {
        name: 'organizations.energy-provider.period',
        path: 'recharge-service/:periodId',
        component: EnergyProviderPeriod
      },
      {
        name: 'organizations.energy-provider.period.lines',
        path: 'recharge-service/:periodId/lines',
        component: EnergyProviderPeriodLines,
      },
    ]
  },
]
