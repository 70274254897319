
import Validator from "@/service/Validator";

export default {
  data() {
    return {
      commonRules: {
        required: v => {
          if(Array.isArray(v)) {
            return v.length > 0 || this.$t("rules.field-required")
          } else {
            return (v !== undefined && v !== null && v !== '') || this.$t("rules.field-required")
          }
        },
        email: v => (!v || Validator.email(v)) || this.$t("rules.email-not-valid"),
        password: v => this.checkPasswordComplexity(v) || this.$t("rules.invalid-complexity-password"),
      }
    }
  },

  computed: {

  },

  methods: {

  }
}