<template>
  <div>
    <v-row v-for="field in filteredFields" :key="field.id">
      <v-col cols="12">
        <form-field
            v-if="field.type === 'text'"
            :label="field.label"
            :value="getFieldValue(field.customFieldId || field.id)"
            @input="updateFieldValue(field, $event)"
            :hint="field.description"
            :rules="getValidationRules(field)"
            :col-field="colField"
        />
        <form-field
            v-else-if="field.type === 'paragraph'"
            :label="field.label"
            :value="getFieldValue(field.customFieldId || field.id)"
            @input="updateFieldValue(field, $event)"
            :hint="field.description"
            type="textarea"
            :rows="3"
            :rules="getValidationRules(field)"
            :col-field="colField"
        />
        <form-field
            v-else-if="field.type === 'number'"
            :label="field.label"
            input-type="number"
            :value="getFieldValue(field.customFieldId || field.id)"
            @input="updateFieldValue(field, $event)"
            :hint="field.description"
            :rules="getValidationRules(field)"
            :col-field="colField"
        />
        <form-field
            v-else-if="field.type === 'bool'"
            :label="field.label"
            type="switch"
            :value="getFieldValue(field.customFieldId || field.id)"
            @input="updateFieldValue(field, $event)"
            :hint="field.description"
            :rules="getValidationRules(field)"
            :col-field="colField"
        />
        <form-field
            v-else-if="field.type === 'choice'"
            :label="field.label"
            type="select"
            :value="getFieldValue(field.customFieldId || field.id)"
            :items="field.options.choices"
            @input="updateFieldValue(field, $event)"
            :hint="field.description"
            :rules="getValidationRules(field)"
            :col-field="colField"
        />
        <form-field
            v-else-if="field.type === 'organization'"
            :label="field.label"
            type="tree-select-organization"
            :value="getFieldValue(field.customFieldId || field.id)"
            @input="updateFieldValue(field, $event)"
            :multiple="field.options.multiple ?? false"
            :hint="field.description"
            :rules="getValidationRules(field)"
            :col-field="colField"
        />
        <form-field
            v-else-if="field.type === 'electricity-index'"
            :label="field.label"
            type="electricity-index-select"
            :value="getFieldValue(field.customFieldId || field.id)"
            @input="updateFieldValue(field, $event)"
            :multiple="field.options.multiple ?? false"
            :hint="field.description"
            :rules="getValidationRules(field)"
            :col-field="colField"
        />
        <form-field
            v-else-if="field.type === 'tariff'"
            :label="field.label"
            type="tariff-select"
            :value="getFieldValue(field.customFieldId || field.id)"
            @input="updateFieldValue(field, $event)"
            :multiple="field.options.multiple ?? false"
            :hint="field.description"
            :rules="getValidationRules(field)"
            :col-field="colField"
            :filter-query="filterQuery"
        />
        <div v-else class="red--text">Type {{ field.type }} not supported</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import rulesMixin from "@mixins/RulesMixin"
import {update} from "store/plugins/all_tests";

export default {
  data() {
    return {
      fieldValues: [],
    }
  },

  mixins: [
    rulesMixin,
  ],

  props: {
    value: { type: Array, default: () => ([]) },
    fields: { type: Array, default: () => ([]) },
    filterQuery: { type: Object, default: () => ({}) },
    colField: Number,
  },

  mounted() {
    if(this.value) {
      this.fieldValues = this.value.map(item => ({
        customFieldId: item.customFieldId || item.id,
        value: item.value,
      }));
    }
  },

  watch: {
    value(data) {
      this.fieldValues = data.map(item => ({
        customFieldId: item.customFieldId || item.id,
        value: item.value,
      }));
    },

    fields(data) {
      const initialValues = data.map(item => ({
        customFieldId: item.customFieldId || item.id,
        value: this.getFieldValue(item.customFieldId || item.id) || this.getDefaultValue(item), // Utilisation de la valeur par défaut si aucune valeur n'est définie
      }));
      this.fieldValues = [...initialValues];
    },
  },

  computed: {
    filteredFields() {
      const fieldsByName = {}
      for(let f of this.fields) {
        fieldsByName[f.id] = f
      }
      const fields = []
      for(let field of this.fields) {
        let f = this.cloneObj(field)
        // translation if needed
        if(f.options?.translateLabelFrom) {
          f.label = this.$tc(f.options.translateLabelFrom, f.options.multiple ? 2 : 1)
        }
        if(f.options?.translateValuesFromPrefix && f.options?.choices?.length > 0) {
          f.options.choices = f.options.choices.map(c => ({text: this.$t(f.options.translateValuesFromPrefix + '.' + c), value: c}))
        }

        if(f.options?.conditional) {
          for(let c of f.options?.conditional) {
            if(c && fieldsByName[c.field]) {
              if (this.checkCondition(c.conditionalType, this.getFieldValue(fieldsByName[c.field].id), c.value)) {
                fields.push(f)
                break;
              } else {
                if(this.getFieldValue(f.id)) {
                  this.updateFieldValue(f, null)
                }
              }
            }
          }
        } else {
          fields.push(f)
        }
      }
      return fields
    }
  },

  methods: {

    checkCondition(conditionalType, currentValue, conditionalValue) {
      switch (conditionalType) {
        case 'eq':
          return currentValue === conditionalValue;
        case 'lt':
          return currentValue < conditionalValue;
        case 'lte':
          return currentValue <= conditionalValue;
        case 'gt':
          return currentValue > conditionalValue;
        case 'gte':
          return currentValue >= conditionalValue;
        default:
          return false;
      }
    },

    getFieldValue(customFieldId) {
      const field = this.fieldValues.find(item => item.customFieldId === customFieldId);
      return field ? field.value : null;
    },

    updateFieldValue(field, value) {
      const customFieldId = field.customFieldId || field.id;
      const index = this.fieldValues.findIndex(item => item.customFieldId === customFieldId);
      if (index !== -1) {
        this.$set(this.fieldValues[index], 'value', value);
      } else {
        this.fieldValues.push({ customFieldId: customFieldId, value });
      }

      this.$emit('input', this.fieldValues);
    },

    getDefaultValue(field) {
      return field.options && field.options.default ? field.options.default : null;
    },

    getValidationRules(field) {
      const rules = [];
      if (field.options && field.options.required) {
        rules.push(this.commonRules.required);
      }
      return rules;
    },
  }
}
</script>

<style lang="scss">

</style>
