import Vue from 'vue'

class ChargingPointRepository {
  search(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point/search',
        method: 'post',
        data,
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
  markers(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point/markers',
        method: 'post',
        data,
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getNotInstalled(imei) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point/getNotInstalled/' + imei,
        method: 'get',
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  get(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point/get/' + id,
        method: 'get',
      })
          .then(response => {
            resolve(response.data)
          }).catch(err => {
        reject(err)
      })
    })
  }

  update(id, data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point/update/' + id,
        method: 'post',
        data
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  chargingPointTypes(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point-type/search',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  chargingPointTypeVendors(query = '') {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point-type-vendor/search',
        method: 'get',
        data: {
          query,
        },
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  installChargingPoint(identifier, locationId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point/install',
        method: 'post',
        data: {
          identifier,
          locationId,
        },
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  statusCounter() {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point/status-counter',
        method: 'get',
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

}


export default new ChargingPointRepository()
