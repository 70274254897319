<template>
  <div>
    <v-autocomplete
        ref="input"
        v-model="data"
        :label="label"
        :loading="loading"
        :items="items"
        v-on="$listeners"
        :rules="rules"
        item-text="fullname"
        item-value="id"
        :search-input.sync="searchText"
        :solo="solo"
        :clearable="clearable"
        :multiple="multiple"
        :append-icon="appendIcon"
        :readonly="readonly"
        :disabled="disabled"
        :placeholder="placeholder"
        @keydown="loadItems(searchText)"
        persistent-placeholder
        no-filter
        hide-details
        hide-no-data
        autocomplete="nope"
    >
      <template #item="{item, on, attrs}">
        <v-list-item v-on="on" v-bind="attrs">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.fullname }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.evcoId }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template #selection="{item}" v-if="!selectionWithNoExtra">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.fullname }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.evcoId }} <b>({{ item.organization?.name ?? "Pas d'organisation" }})</b>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

    </v-autocomplete>
  </div>
</template>

<script>

import CustomerRepository from "@repository/CustomerRepository";

export default {
  data(){
    return {
      loading: false,
      items: [],
      data: this.value,
      searchText: "",
      oldValue: this.value,
    }
  },

  model: {
    prop: 'value',
    event: 'input',
  },

  props: {
    value: String,
    type: String,
    clearable: Boolean,
    readonly: Boolean,
    disabled: Boolean,
    multiple: Boolean,
    solo: Boolean,
    rules: Array,
    appendIcon: String,
    placeholder: String,
    label: String,
    organizationId: String,
    selectionWithNoExtra: Boolean,
  },

  watch: {
    searchText() {

    },

    data(val) {
      this.$emit('input', val)
      this.oldValue = val
    },

    value(val) {
      this.data = val
      this.loadItems(val)
    },

    organizationId() {
      if(this.searchText) {
        this.searchText = ''
      } else {
        this.loadItems('')
      }
    }
  },

  mounted() {
    if(this.data) {
      this.loadItems(this.data)
    }
  },

  computed: {

  },

  methods: {
    loadItems(searchText) {
      this.loading = true
      CustomerRepository.search({
        searchText,
        offset: 0,
        limit: 30,
        sortBy: 'createdAt',
        sortOrder: 'desc',
        organizationId: this.organizationId,
      }).then(result => {
        this.items = result.items.map(c => {
          let fullname = []
          if(c.firstname) {
            fullname.push(c.firstname)
          }
          if(c.lastname) {
            fullname.push(c.lastname)
          }
          if(fullname.length === 0) {
            if(c.email) {
              fullname.push(c.email)
            } else if(c.email) {
              fullname.push(c.phone)
            }
          }
          return {
            ...c,
            fullname: fullname.join(' '),
          }
        })
        this.loading = false
        if(this.items.find(c => c.id === this.oldValue)) {
          this.$nextTick(() => {
            this.data = this.oldValue
          })
        }
      }).catch(err => {
        this.$dialog.notify.error(err.data.message ?? err.message)
      })
    },

    focus() {
      this.$refs.input.focus()
    }
  }
}
</script>

<style lang="scss">

</style>
