<template>
  <div v-if="ready">
     <router-view></router-view>
  </div>
</template>

<script>
  import axios from "axios";
  import {mapGetters} from "vuex";
  import dayjs from "dayjs";
  import {EventBus} from "@includes/EventBus";


  import { io } from "socket.io-client";


  export default {

    data() {
      return {
        ready: false,
      }
    },

    mounted() {
      this.initLocal()


      axios({url: '/config.json'}).then(response => {
        let config = {}
        for(let a in response.data) {
          config[a] = response.data[a] || process.env['VUE_APP_' + a]
        }
        this.$store.commit('appConfig', config)
        this.initNotifierClient()
      })


      this.$nextTick(() => this.ready = true);
    },

    watch: {
      currentLocale() {
        this.initLocal()

      },
    },

    computed: {
      ...mapGetters([
          'appConfig'
      ])
    },

    methods: {

      initLocal() {
        const locale = this.currentLocale
        dayjs.locale(locale)
        this.$i18n.locale = locale
        this.$vuetify.lang.current = locale
        this.$dialog._components.confirm.options.actions.true.text = this.$t('yes')
        this.$dialog._components.confirm.options.actions.false.text = this.$t('no')
      },

      initNotifierClient() {
        let socket = io(this.appConfig.NOTIFIER_URL)
        socket.on("message", obj => {
          EventBus.$emit('on-' + obj.key, obj)
        });
      },
    }
  }
</script>
