<template>
  <v-app>
    <v-main class="layout-light">
      <v-container fluid fill-height class="light">
        <div class="bg"></div>
        <v-layout flex align-center>
          <div class="wrapper">
            <div class="content">
              <router-view></router-view>
            </div>
          </div>
          <div class="car-back">
            <img src="@images/car.back.png" />
          </div>
        </v-layout>
        <v-overlay color="white" :value="loading" z-index="99999">
          <v-progress-circular
              color="primary"
              indeterminate
              size="90"
          ></v-progress-circular>
        </v-overlay>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {EventBus} from "@includes/EventBus";

export default {
  data() {
    return {
      loading: false,
    }
  },


  mounted() {
    EventBus.$on('loading', this.showLoading)
  },

  destroyed() {
    EventBus.$off('loading', this.showLoading)
  },


  methods: {
    showLoading(show) {
      this.loading = show
    },
  }
}
</script>

<style lang="scss">
.light {
  padding: 0;
  background-image: linear-gradient(45deg, #f8f8f8, #f9f9f9);
  input:-webkit-autofill, input:focus:-webkit-autofill {
    background-color: #fff !important;
    -webkit-box-shadow: 0 0 0 100px #fff  inset;
  }

  .wrapper {
    width: 100vw;
    z-index: 2;
    .content {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: center;
      & > div {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .car-back {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    overflow: hidden;
    @media only screen and (max-width: 600px) {
      display: none;
    }
    img {
      width: 63vw;
      transform: translateY(25%);
    }
  }



  & > .layout {
    z-index: 1;
  }
  & > .bg {
    position: absolute;
    overflow: hidden;
    display: flex;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    align-items: flex-end;
    justify-content: flex-end;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: url(~@images/bandes-haut.png);
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: top left;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: url(~@images/bandes-bas.png);
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: bottom right;
    }

  }


}
</style>