<template>
  <v-autocomplete
      ref="input"
      v-model="data"
      :label="label"
      :loading="loading"
      :items="itemsFiltered"
      v-on="$listeners"
      :rules="rules"
      item-text="label"
      item-value="code"
      :solo="solo"
      :clearable="clearable"
      :append-icon="appendIcon"
      :readonly="readonly"
      :disabled="disabled"
      :placeholder="placeholder"
      :persistent-placeholder="persistentPlaceholder"
      hide-details
  ></v-autocomplete>
</template>

<script>
import AvailableRepository from "@repository/AvailableRepository";

export default {
  data(){
    return {
      loading: false,
      items: [],
      data: this.value,
    }
  },

  model: {
    prop: 'value',
    event: 'input',
  },

  props: {
    value: String,
    type: String,
    clearable: Boolean,
    readonly: Boolean,
    disabled: Boolean,
    solo: Boolean,
    rules: Array,
    appendIcon: String,
    placeholder: String,
    persistentPlaceholder: Boolean,
    label: String,
    filter: Function,
  },

  watch: {
    data(val) {
      this.$emit('input', val)
    },

    value(val) {
      this.data = val
    }
  },

  mounted() {

    this.loadItems()
  },

  computed: {
    itemsFiltered() {
      if (this.filter) {
        return this.items.filter(this.filter)
      }
      return this.items
    }
  },

  methods: {
    loadItems() {
      let repository
      switch(this.type) {
        case AvailableRepository.TYPE_LANGUAGE:
          repository = AvailableRepository.languages()
          break;
        case AvailableRepository.TYPE_CURRENCY:
          repository = AvailableRepository.currencies()
          break;
        case AvailableRepository.TYPE_COUNTRY:
          repository = AvailableRepository.countries()
          break;
        case AvailableRepository.TYPE_TIMEZONE:
          repository = AvailableRepository.timezones()
          break;
        default:
          throw new Error("type not valid")
      }

      this.loading = true
      repository.then(items => {
        this.items = items
        this.loading = false
      }).catch(err => {
        this.$dialog.notify.error(err.data.message ?? err.message)
      })
    },

    focus() {
      this.$refs.input.focus()
    }
  }
}
</script>

<style lang="scss">

</style>
