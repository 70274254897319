import Vue from 'vue'
import store from '../stores/index'


class AvailableRepository {

  TYPE_LANGUAGE = 'language'
  TYPE_COUNTRY = 'country'
  TYPE_CURRENCY = 'currency'
  TYPE_TIMEZONE = 'timezone'

  countries(locale) {
    return new Promise((resolve, reject) => {
      if(!locale) {
        locale = this.currentLocale();
      }
      Vue.auth.fetch({
        url: '/api/shared/available/countries/' + locale,
      }).then(
          response => resolve(response.data)
      ).catch(
          err => reject(err)
      )
    })
  }

  languages(locale) {
    return new Promise((resolve, reject) => {
      if(!locale) {
        locale = this.currentLocale();
      }
      Vue.auth.fetch({
        url: '/api/shared/available/languages/' + locale,
      }).then(
          response => resolve(response.data)
      ).catch(
          err => reject(err)
      )
    })
  }

  currencies(locale) {
    return new Promise((resolve, reject) => {
      if(!locale) {
        locale = this.currentLocale();
      }
      Vue.auth.fetch({
        url: '/api/shared/available/currencies/' + locale,
      }).then(
          response => resolve(response.data)
      ).catch(
          err => reject(err)
      )
    })
  }

  timezones() {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/shared/available/timezones',
      }).then(
          response => resolve(response.data)
      ).catch(
          err => reject(err)
      )
    })
  }



  currentLocale() {
    return store.state.core.currentLocale
  }

}

export default new AvailableRepository()