<template>
  <v-row class="ls-input" v-if="data[selectedLanguageIndex]">
    <v-col>
      <div class="label mt-1" v-if="label && wysiwyg">
        {{ label }}
      </div>
      <wysiwyg-field
          v-if="wysiwyg"
          :disabled="disabled"
          :language="selectedLanguage"
          v-model="data[selectedLanguageIndex].value"
      />
      <v-textarea
          v-else
          :label="label"
          ref="input"
          rows="2"
          @blur="$emit('blur')"
          @focus="$emit('focus')"
          :solo="solo"
          :readonly="disabled"
          :placeholder="placeholder"
          auto-grow
          v-model="data[selectedLanguageIndex].value"
          hide-details
      >
      </v-textarea>
    </v-col>
    <v-col class="flex-grow-0" v-if="!disableLanguageBtns">
      <v-btn-toggle v-model="selectedLanguage" mandatory  color="primary" shaped>
        <v-btn :value="language" v-for="language in languages" :key="language" @click="selectedLanguage = language">
          {{ flags(language) }}
        </v-btn>
      </v-btn-toggle>
    </v-col>

  </v-row>
</template>

<script>
import WysiwygField from "@blocks/WysiwygField.vue";
import {mapGetters} from "vuex";


export default {
  components: {
    WysiwygField,
  },

  data() {
    return {
      data: [],
      selectedLanguage: null,
    }
  },

  model: {
    event: 'input',
    prop: 'value',
  },

  props: {
    label: String,
    disabled: Boolean,
    wysiwyg: Boolean,
    value: Array,
    solo: Boolean,
    disableLanguageBtns: Boolean,
    placeholder: String,
  },


  mounted() {
    this.selectedLanguage = this.activeFormLocale ?? this.currentLocale
    this.initData(this.value)
  },

  watch: {
    value() {
      this.initData(this.value)
    },

    selectedLanguage(val, oldVal) {
      if(oldVal !== val) {
        this.$store.commit('activeFormLocale', val)
      }
    },

    activeFormLocale(val, oldVal) {
      if(val !== oldVal) {
        this.selectedLanguage = val
      }
    },

    currentLocale(val) {
      this.selectedLanguage = val
    }

  },


  computed: {
    ...mapGetters([
      'activeFormLocale',
    ]),

    languages() {
      const allLanguages = ['en', 'fr', 'nl']

      const languages = [
          this.currentLocale,
      ]
      allLanguages.forEach(lng => {
        if(lng !== this.currentLocale) {
          languages.push(lng)
        }
      })

      return languages
    },

    selectedLanguageIndex() {
      let index = this.data?.findIndex(d => d.language === this.selectedLanguage)
      return index >= 0 ? index : 0
    },

  },

  methods: {
    initData(data) {
      const dataByLanguages = {}
      if(data) {
        data.forEach(d => {
          dataByLanguages[d.language] = d
        })
      }

      let newField = false

      const localisedStrings = []
      this.languages.forEach(lng => {
        if(dataByLanguages[lng]) {
          localisedStrings.push(dataByLanguages[lng])
        } else {
          localisedStrings.push({language: lng, value: null})
          newField = true
        }
      })


      this.data =  localisedStrings
      if(newField) {
        this.$emit('input', localisedStrings)
      }
    },

    flags(language) {
      const languages = {
        'fr': '🇫🇷',
        'en': '🇬🇧',
        'nl': '🇳🇱',
      }
      return languages[language]
    }
  }
}
</script>

<style lang="scss">
  .ls-input {
    textarea::placeholder {
      font-weight: 100 !important;
      font-style: italic;
    }
  }
</style>