import {cookieStorage} from '@plugins/vue-jwt/sessionStore'
import SecurityRepository from "@repository/SecurityRepository";

let currentLocal = (navigator.language || navigator.userLanguage).replace('-', '_')
if(currentLocal.split('_').length > 1) {
    currentLocal = currentLocal.split('_')[0];
}

// state
const state = {
    appConfig: null,
    currentUser: null,
    currentLocale: cookieStorage.get('local') || currentLocal,
    activeFormLocale: null,
}

// getters
const getters = {
    appConfig(state) {
        return state.appConfig
    },
    currentLocale(state) {
        return state.currentLocale
    },
    currentUser(state) {
        return state.currentUser
    },
    activeFormLocale(state) {
        return state.activeFormLocale
    },
}

// actions
const actions = {

    fetchCurrentUser({ commit }) {
        return new Promise((resolve, reject) => {
            SecurityRepository.me().then(user => {
                commit('currentUser', user)
                resolve(user)
            }).catch(err => {
                reject(err)
            })
        })
    },

}

// mutations
const mutations = {

    appConfig(state, value) {
        state.appConfig = value
    },

    currentLocale(state, value) {
        state.currentLocale = value
        cookieStorage.set('local', value)
    },

    currentUser(state, value) {
        state.currentUser = value
    },

    activeFormLocale(state, value) {
        state.activeFormLocale = value
    },

}

export default {
    state,
    getters,
    actions,
    mutations
}