const ChargingPointSearch = () => import(/* webpackChunkName: "charging-point" */ "@pages/ChargingPoint/ChargingPointSearch")
const ChargingPointAdd = () => import(/* webpackChunkName: "charging-point" */ "@pages/ChargingPoint/ChargingPointAdd")
const ChargingPointDetail = () => import(/* webpackChunkName: "charging-point" */ "@pages/ChargingPoint/ChargingPointDetail")
const ChargingPointDetailInformation = () => import(/* webpackChunkName: "charging-point" */ "@pages/ChargingPoint/Detail/ChargingPointDetailInformation")
const ChargingPointDetailEdit = () => import(/* webpackChunkName: "charging-point" */ "@pages/ChargingPoint/Detail/ChargingPointDetailEdit")
const ChargingPointDetailManage = () => import(/* webpackChunkName: "charging-point" */ "@pages/ChargingPoint/Detail/ChargingPointDetailManage")
const ChargingPointDetailUsages = () => import(/* webpackChunkName: "charging-point" */ "@pages/ChargingPoint/Detail/ChargingPointDetailUsages")
const ChargingPointDetailReviews = () => import(/* webpackChunkName: "charging-point" */ "@pages/ChargingPoint/Detail/ChargingPointDetailReviews")
const ChargingPointDetailPrices = () => import(/* webpackChunkName: "charging-point" */ "@pages/ChargingPoint/Detail/ChargingPointDetailPrices")
const ChargingPointDetailMessages = () => import(/* webpackChunkName: "charging-point" */ "@pages/ChargingPoint/Detail/ChargingPointDetailMessages")
const ChargingPointDetailIssues = () => import(/* webpackChunkName: "charging-point" */ "@pages/ChargingPoint/Detail/ChargingPointDetailIssues")
const ChargingPointDetailIssueAdd = () => import(/* webpackChunkName: "charging-point" */ "@pages/ChargingPoint/Detail/Issue/ChargingPointDetailIssueAdd")
const ChargingPointDetailIssueDetail = () => import(/* webpackChunkName: "charging-point" */ "@pages/ChargingPoint/Detail/Issue/ChargingPointDetailIssueDetail")
const ChargingPointDetailLicences = () => import(/* webpackChunkName: "charging-point" */ "@pages/ChargingPoint/Detail/ChargingPointDetailLicences")

const ChargingPointTypeSearch = () => import(/* webpackChunkName: "charging-point" */ "@pages/ChargingPointType/ChargingPointTypeSearch")
const ChargingPointTypeDetail = () => import(/* webpackChunkName: "charging-point" */ "@pages/ChargingPointType/ChargingPointTypeDetail")
const ChargingPointTypeDetailInformation = () => import(/* webpackChunkName: "charging-point" */ "@pages/ChargingPointType/Detail/ChargingPointTypeInformation")
const ChargingPointTypeDetailEdit = () => import(/* webpackChunkName: "charging-point" */ "@pages/ChargingPointType/Detail/ChargingPointTypeEdit")
const ChargingPointTypeChargingPoints = () => import(/* webpackChunkName: "charging-point" */ "@pages/ChargingPointType/Detail/ChargingPoints")

export default [
  {
    name: 'charging-points',
    path: 'charging-points',
    component: ChargingPointSearch,
  },
  {
    name: 'charging-points.add',
    path: 'charging-points/add',
    component: ChargingPointAdd,
  },
  {
    path: 'charging-points/:id',
    component: ChargingPointDetail,
    children: [
      {
        name: 'charging-points.detail',
        path: '',
        component: ChargingPointDetailInformation,
      },
      {
        name: 'charging-points.edit',
        path: 'edit',
        component: ChargingPointDetailEdit,
      },
      {
        name: 'charging-points.detail.manage',
        path: 'manage',
        component: ChargingPointDetailManage,
      },
      {
        name: 'charging-points.detail.usages',
        path: 'usages',
        component: ChargingPointDetailUsages,
      },
      {
        name: 'charging-points.detail.issues',
        path: 'issues',
        component: ChargingPointDetailIssues,
      },
      {
        name: 'charging-points.detail.issues.add',
        path: 'issues/add',
        component: ChargingPointDetailIssueAdd,
      },
      {
        name: 'charging-points.detail.issues.detail',
        path: 'issues/:issueId',
        component: ChargingPointDetailIssueDetail,
      },
      {
        name: 'charging-points.detail.reviews',
        path: 'reviews',
        component: ChargingPointDetailReviews,
      },
      {
        name: 'charging-points.detail.prices',
        path: 'prices',
        component: ChargingPointDetailPrices,
      },
      {
        name: 'charging-points.detail.messages',
        path: 'messages',
        component: ChargingPointDetailMessages,
      },
      {
        name: 'charging-points.detail.licences',
        path: 'licences',
        component: ChargingPointDetailLicences,
      },
    ]
  },
  {
    name: 'charging-point-types',
    path: 'charging-point-types',
    component: ChargingPointTypeSearch,
  },
  {
    path: 'charging-point-types/:id',
    component: ChargingPointTypeDetail,
    children: [
      {
        name: 'charging-point-types.detail',
        path: '',
        component: ChargingPointTypeDetailInformation,
      },
      {
        name: 'charging-point-types.edit',
        path: 'edit',
        component: ChargingPointTypeDetailEdit,
      },
      {
        name: 'charging-point-types.charging-points',
        path: 'charging-points',
        component: ChargingPointTypeChargingPoints,
      },
    ]
  }
]