import Vue from 'vue'
import dayjs from 'dayjs'
import Tools from "@includes/tools";


Vue.filter('ucfirst', Tools.ucfirst);


Vue.filter('nl2br', function(value) {

    if (typeof value === 'undefined' || value === null) {
        return '';
    }
    return (value + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br />' + '$2');
});


Vue.filter('formatDate', function(value, format) {
    if (!format) {
        format = "DD/MM/YY [à] HH[h]mm"
    }
    if (value) {
        return dayjs(String(value)).format(format)
    }
    return value
});

