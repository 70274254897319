import Vue from 'vue'

class PriceIndexRepository {

  search(searchText, offset = 0, limit = 20, orderBy = 'createdAt', orderDirection = 'desc') {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/pricing/price-index/search',
        method: 'post',
        data: {
          searchText,
          offset,
          limit,
          orderBy,
          orderDirection,
        },
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  create(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/pricing/price-index',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  update(id, data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/pricing/price-index/' + id,
        method: 'put',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/pricing/price-index/' + id,
        method: 'delete',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  get(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/pricing/price-index/' + id,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default new PriceIndexRepository()