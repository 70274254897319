import { VStepper } from 'vuetify/lib';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
// stepper step types by component name, so the VStepperIcon
// is able to display different types of stepper steps
const STEPPER_STEP_COMPONENTS = ['v-stepper-step', 'v-stepper-step-icon']
// comparing name to possible stepper step component names
const isStepperStepComponent = (componentList) => {
  return (name) => componentList.includes(name)
}
// function to check if a string is in the allowed componentList
const isStep = isStepperStepComponent(STEPPER_STEP_COMPONENTS)



export const VStepperStepIcon = {
  name: 'v-stepper-step-icon',
  // eslint-disable-next-line
  extends: VStepperStep,
  methods: {
    genStepContent() {
      const children = [];
      if (this.hasError) {
        children.push(this.genIcon(this.errorIcon));
      } else if (this.complete) {
        if (this.editable) {
          children.push(this.genIcon(this.editIcon));
        } else {
          children.push(this.genIcon(this.completeIcon));
        }
      } else if (this.$attrs.icon) {
        // this "else if" branch is added, so the step can
        // display custom icons
        children.push(this.genIcon(this.$attrs.icon));
      } else {
        children.push(String(this.step));
      }
      return children;
    },
  },
};

export const VStepperIcon = {
  // name is in dash-case, as Vuetify uses that
  // eslint-disable-next-line
  name: 'v-stepper-icon',
  extends: VStepper,
  methods: {
    register(item) {
      if (isStep(item.$options.name)) {
        this.steps.push(item);
      } else if (item.$options.name === 'v-stepper-content') {
        item.isVertical = this.vertical;
        this.content.push(item);
      }
    },
    unregister(item) {
      if (isStep(item.$options.name)) {
        this.steps = this.steps.filter((i) => i !== item);
      } else if (item.$options.name === 'v-stepper-content') {
        item.isVertical = this.vertical;
        this.content = this.content.filter((i) => i !== item);
      }
    },
  },
};