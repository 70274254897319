<template>
  <v-btn class="back-btn-circle" dark rounded color="primary" @click="click">
    <v-icon>ico-back</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    to: Object,
  },

  methods: {
    click() {
      if(this.to) {
        this.$router.push(this.to)
      } else  {
        this.$router.back()
      }
    }
  }
}
</script>

<style lang="scss">
  .back-btn-circle {
    border-radius: 50% !important;
    min-width: 0px !important;
    width: 70px !important;
    height: 70px !important;
  }

</style>