import SharedRepository from "@repository/SharedRepository";

export default class Validator {

  static email(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  static async iban(value) {
    const data = await SharedRepository.checkIban(value)
    return data.isIbanValid
  }

  static async bic(value) {
    const data = await SharedRepository.checkBic(value)
    return data.isBicValid
  }

}