import Vue from 'vue'

class CustomerRepository {
  controller = null
  search(data) {
    return new Promise((resolve, reject) => {


      if(this.controller) {
        this.controller.abort()
      }
      this.controller = new AbortController()


      Vue.auth.fetch({
        url: '/api/customer/search',
        method: 'post',
        data,
        signal: this.controller.signal
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  get(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/customer/get/' + id,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getByIdentifier(identifier) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/customer/by-identifier/' + identifier,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  create(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/customer',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  update(id, data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/customer/update/' + id,
        method: 'post',
        data
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  addHomeChargingPoint(id, chargingPointId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/customer/' + id + '/home-charging-point',
        method: 'post',
        data: {
          chargingPointId
        }
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  deleteHomeChargingPoint(id, chargingPointId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/customer/' + id + '/home-charging-point/' + chargingPointId,
        method: 'delete',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  addFreeOrganization(id, organizationId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/customer/' + id + '/free-organization',
        method: 'post',
        data: {
          organizationId
        }
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  deleteFreeOrganization(id, organizationId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/customer/' + id + '/free-organization/' + organizationId,
        method: 'delete',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  requestMeterDeviceReadings(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/customer/' + id + '/request-meter-device-readings',
        method: 'post',
      }).then(response => {
          resolve(response.data)
      }).catch(err => {
          reject(err)
      })
    })
  }
}


export default new CustomerRepository()
