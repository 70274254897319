<template>
  <div>
    <v-autocomplete v-if="autocomplete"
        ref="input"
        v-on="$listeners"
        v-model="data"
        hide-details
        solo
        :items="items"
        :placeholder="placeholder"
        prepend-inner-icon="ico-magnify"
        :loading="loading"
        :search-input.sync="searchLocal"
        class="search-field"
        :class="{open: opened}"
        :item-text="itemText"
        :item-value="itemValue"
        :return-object="returnObject"
        :menu-props="{
          bottom: true,
        }"
        hide-no-data
        no-filter
    >
      <template #item="{item}">
        <slot name="item" :item="item"></slot>
      </template>

      <template #selection="{item}">
        <slot name="selection" :item="item"></slot>
      </template>

      <template #append-item v-if="moreResultTo && items.length > 1">
        <div class="search-bottom">
          <v-btn :to="moreResultTo">{{ $t("show-more-results") }}</v-btn>
        </div>
      </template>
    </v-autocomplete>
    <v-text-field
        v-else
        class="search-field"
        v-on="$listeners"
        v-model="searchLocal"
        hide-details
        solo
        :placeholder="placeholder"
        :clearable="clearable"
        prepend-inner-icon="ico-magnify"
        :loading="loading"
    ></v-text-field>
  </div>
</template>

<script>


export default {


  data() {
    return {
      data: this.value,
      searchLocal: this.search,
      open: false,
    }
  },

  model: {
    event: 'input',
    prop: 'value',
  },

  props: {
    value: [String, Object],
    placeholder: String,
    search: String,
    loading: Boolean,
    items: {type: Array, default: () => ([])},
    itemText: {type: String, default: 'label'},
    itemValue: {type: String, default: 'id'},
    returnObject: {type: Boolean, default: false},
    moreResultTo: {type: [Object,String], default: null},
    autocomplete: Boolean,
    clearable: Boolean,
  },

  watch: {
    data(val) {
      this.$emit('input', val)
    },

    value(val) {
      this.data = val
    },

    searchLocal(val) {
      this.$emit('update:search', val)
    },

    search(val) {
      this.searchLocal = val
    },
  },

  mounted() {


  },

  computed: {

    input() {
      return this.$refs.input
    },


    opened() {

      let state = this.items.length > 0
      if(this.$refs.input && this.$refs.input.$refs && this.$refs.input.$refs.menu) {
        state = this.$refs.input.$refs.menu.isActive
      }
      return state
    }
  },

  methods: {

  }
}
</script>

<style lang="scss">
.search-field {
  box-shadow: 0 10px 30px #00000029;
  .v-input__control {
    .v-input__slot {
      border: 1px solid #758093 !important;
      .v-text-field__slot {
        input {
          color: #E53C22 !important;
        }
      }

      &:before {
        border-color: #70707080 !important;
      }
    }
  }
  &.open {
    .v-input__slot {
      border-radius: 4px 4px 0 0;
      border-bottom: 0 !important;
    }
  }
}
.v-select-list {
  border: 1px solid #758093 !important;
  border-top: 0 !important;
  .search-bottom {
    text-align: center;
    padding: 10px 20px;
    margin: 10px 15px;
    background-color: #5F65691A;
    .v-btn {
      color: var(--v-primary-base);
      border: 1px solid var(--v-primary-base);
      padding: 13px 20px;
      border-radius: 60px;
    }
  }
}


</style>