
const CustomerSearch = () => import(/* webpackChunkName: "customer" */ "@pages/Customer/CustomerSearch")
const CustomerDetail = () => import(/* webpackChunkName: "customer" */ "@pages/Customer/CustomerDetail")
const CustomerDetailInformation = () => import(/* webpackChunkName: "customer" */ "@pages/Customer/Detail/CustomerDetailInformation")
const CustomerDetailUsages = () => import(/* webpackChunkName: "customer" */ "@pages/Customer/Detail/CustomerDetailUsages")
const CustomerDetailInvoices = () => import(/* webpackChunkName: "customer" */ "@pages/Customer/Detail/CustomerDetailInvoices")
const CustomerDetailFreeChargingPoints = () => import(/* webpackChunkName: "customer" */ "@pages/Customer/Detail/CustomerDetailFreeChargingPoints")
const CustomerDetailLoginToAccount = () => import(/* webpackChunkName: "customer" */ "@pages/Customer/Detail/CustomerDetailLoginToAccount")
const CustomerDetailRfid = () => import(/* webpackChunkName: "customer" */ "@pages/Customer/Detail/CustomerDetailRfid")
const CustomerDetailReviews = () => import(/* webpackChunkName: "customer" */ "@pages/Customer/Detail/CustomerDetailReviews")
const CustomerDetailPaymentMethods = () => import(/* webpackChunkName: "customer" */ "@pages/Customer/Detail/CustomerDetailPaymentMethods")
const CustomerDetailEdit = () => import(/* webpackChunkName: "customer" */ "@pages/Customer/Detail/CustomerDetailEdit")
const CustomerAdd = () => import(/* webpackChunkName: "customer" */ "@pages/Customer/CustomerAdd")

export default [
  {
    name: 'customers',
    path: 'customers',
    component: CustomerSearch,
  },
  {
    name: 'customer.add',
    path: 'customer/add',
    component: CustomerAdd,
  },
  {
    path: 'customer/:id',
    component: CustomerDetail,
    children: [
      {
        name: 'customer.detail',
        path: '',
        component: CustomerDetailInformation,
      },
      {
        name: 'customer.edit',
        path: 'edit',
        component: CustomerDetailEdit,
      },
      {
        name: 'customer.detail.usages',
        path: 'usages',
        component: CustomerDetailUsages,
      },
      {
        name: 'customer.detail.free-charging-points',
        path: 'free-charging-points',
        component: CustomerDetailFreeChargingPoints,
      },
      {
        name: 'customer.detail.login-to-account',
        path: 'login-to-account',
        component: CustomerDetailLoginToAccount,
      },
      {
        name: 'customer.detail.invoices',
        path: 'invoices',
        component: CustomerDetailInvoices,
      },
      {
        name: 'customer.detail.rfid',
        path: 'rfid',
        component: CustomerDetailRfid,
      },
      {
        name: 'customer.detail.reviews',
        path: 'reviews',
        component: CustomerDetailReviews,
      },
      {
        name: 'customer.detail.payment-methods',
        path: 'payment-methods',
        component: CustomerDetailPaymentMethods,
      },

    ]
  },
]