<template>
  <v-menu
      v-model="menu"
      ref="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
      :disabled="loading || readonly || disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="formatedDate"
          :label="label"
          v-bind="attrs"
          v-on="on"
          autocomplete="off"
          readonly
          @click:clear="date = null"
          :prepend-icon="prependIcon"
          :disabled="loading || disabled"
          :outlined="outlined"
          :solo="solo"
          :clearable="clearable"
          :rules="rules"
          :hide-details="hideDetails"
          :placeholder="placeholder"
          :persistent-placeholder="persistentPlaceholder"
      ></v-text-field>
    </template>
    <v-date-picker
        label="Date"
        v-model="date"
        :first-day-of-week="1"
        no-title
        :range="range"
        :type="type"
    />
  </v-menu>
</template>

<script>
import dayjs from "dayjs";

export default {
  data() {
    return {
      date: this.value,
      menu: false,
    }
  },

  props: {
    value: {type: [String,Array]},
    label: {type: String},
    prependIcon: {type: String},
    clearable: {type: Boolean},
    solo: {type: Boolean},
    outlined: {type: Boolean},
    placeholder: {type: String},
    persistentPlaceholder: {type: Boolean},
    loading: {type: Boolean},
    range: {type: Boolean},
    rules: {type: Array},
    disabled: {type: Boolean},
    readonly: {type: Boolean},
    hideDetails: {type: Boolean},
    type: String,
  },

  model: {
    prop: 'value',
  },

  mounted() {

  },

  watch: {
    value(val) {
      this.date = val
    },

    date(val) {


      if(!val) {
        this.$emit('input', val)
      }


      if(this.menu && val) {
        if(this.range) {
          if(val.length === 2) {
            let dates = val
            dates.sort()
            this.$emit('input', dates)
            this.menu = false
          }
        } else {
          if(val) {
            this.$emit('input', val)
            this.menu = false
          }
        }
      }
    },

    menu(val) {
      if(!val) {
        if(this.range) {
          if(this.date.length < 2) {
            this.date = null
          }
        }
      }
    }
  },

  computed: {
    formatedDate: {
      get() {

        if(this.type === 'month') {
          let date = dayjs(this.date)
          if(date.isValid()) {
            return date.format('MMMM YYYY').toUpperCase()
          }
        }

        if(this.date) {

          if(this.range) {

            let dates = this.cloneObj(this.date)


            dates.sort()


            let str = 'du '
            if(dates[0]) {
              let date1 = dayjs(dates[0])
              if(date1.isValid()) {
                str += date1.format(this.$t('format_date'))
              }
            }
            if(dates[1]) {
              let date2 = dayjs(dates[1])
              if(date2.isValid()) {
                str += ' au ' + date2.format(this.$t('format_date'))
              }
            } else {
              str += ' au ?'
            }
            return str
          } else {
            let date = dayjs(this.date)
            if(date.isValid()) {
              return date.format(this.$t('format_date'))
            }
          }

        }
        return null
      },
      set(val) {
        this.date = this.parseDate(val)
      }
    },
  },

  methods: {
    parseDate(val) {
      if(val) {
        let date = dayjs(val, 'DD/MM/YYYY')
        if(date.isValid()) {
          return date.format('YYYY-MM-DD')
        }
      } else {
        return null
      }
    },
  }
}
</script>

<style lang="scss">

</style>