
import engine from 'store/src/store-engine'


// Storages type
import allStore from 'store/storages/all'
import sessionStore from 'store/storages/sessionStorage'
import oldFFGlobalStore from 'store/storages/oldFF-globalStorage'
import oldIEUserDataStore from 'store/storages/oldIE-userDataStorage'
import cookieStore from 'store/storages/cookieStorage'
import memoryStore from 'store/storages/memoryStorage'


// Plugins
import json2Plugin from 'store/plugins/json2'
import expirePlugin from 'store/plugins/expire'


// Storages Engine
export const localStorage = engine.createStore(allStore, [json2Plugin, expirePlugin])
export const sessionStorage = engine.createStore([sessionStore, oldFFGlobalStore, oldIEUserDataStore, cookieStore, memoryStore], [json2Plugin])
export const cookieStorage = engine.createStore([cookieStore, memoryStore], [json2Plugin])


export default localStorage