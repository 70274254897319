<template>
  <div>
    <v-autocomplete
        ref="input"
        v-model="data"
        :label="label"
        :loading="loading"
        :items="items"
        v-on="$listeners"
        :rules="rules"
        item-text="label"
        item-value="id"
        :search-input.sync="searchText"
        :solo="solo"
        :chips="chips"
        :deletable-chips="deletableChips"
        :clearable="clearable"
        :append-icon="appendIcon"
        :readonly="readonly"
        :disabled="disabled"
        :placeholder="placeholder"
        @keydown="loadItems(searchText)"
        :multiple="multiple"
        no-filter
        hide-details
        hide-no-data
        autocomplete="nope"
    >


    </v-autocomplete>
  </div>
</template>

<script>

import SecurityRepository from "@repository/SecurityRepository";

export default {
  data(){
    return {
      loading: false,
      items: [],
      data: this.value,
      searchText: "",
    }
  },

  model: {
    prop: 'value',
    event: 'input',
  },

  props: {
    value: String,
    type: String,
    clearable: Boolean,
    readonly: Boolean,
    disabled: Boolean,
    multiple: Boolean,
    chips: Boolean,
    deletableChips: Boolean,
    solo: Boolean,
    rules: Array,
    appendIcon: String,
    placeholder: String,
    label: String,
    organizationId: String,
    selectionWithNoExtra: Boolean,
  },

  watch: {

    data(val) {
      this.$emit('input', val)
    },

    value(val) {
      this.data = val
      this.loadItems(val)
    },

    organizationId() {
      if(this.searchText) {
        this.searchText = ''
      } else {
        this.loadItems('')
      }
    }
  },

  mounted() {
    this.loadItems(this.data)
  },

  computed: {

  },

  methods: {
    loadItems(searchText) {
      if(Array.isArray(searchText)) {
        searchText = searchText.join(',')
      }
      this.loading = true
      SecurityRepository.search({
        organizationId: this.organizationId,
        query: searchText ?? '',
        limit: 50,
        orderBy: 'lastname',
        orderDirection: 'asc'
      }).then(result => {
        this.items = result.items.map(item => ({
          ...item,
          label: this.userLabel(item),
        }))
        this.loading = false
      }).catch(err => {
        this.notifyError(err)
      })
    },

    focus() {
      this.$refs.input.focus()
    },

    userLabel(user) {
      let tmp = []
      if(user.firstname) {
        tmp.push(user.firstname)
      }
      if(user.lastname) {
        tmp.push(user.lastname)
      }
      if(tmp.length === 0) {
        tmp.push(user.email)
      } else {
        tmp.push(' (' + user.email + ')')
      }
      return tmp.join(' ')
    },
  }
}
</script>

<style lang="scss">

</style>
