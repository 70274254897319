import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';
import {cookieStorage} from "@plugins/vue-jwt/sessionStore";
import en from 'vuetify/es5/locale/en'
import fr from 'vuetify/es5/locale/fr'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: {fr, en},
    current: cookieStorage.get('local') || 'en',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#E53C22',
        secondary: colors.grey.darken3,
        accent: colors.orange.darken1,
        error: colors.red.base,
        warning: colors.yellow.darken4,
        info: colors.grey.lighten1,
        success: '#00897E',
      },
    },
  },
});
