<template>
  <v-menu
      v-model="menu"
      ref="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
      :disabled="loading || readonly"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="time"
          :label="label"
          v-bind="attrs"
          v-on="on"
          autocomplete="off"
          readonly
          :prepend-icon="prependIcon"
          :disabled="loading"
          :clearable="clearable"
          :rules="rules"
          :hide-details="hideDetails"
      ></v-text-field>
    </template>
    <v-time-picker
        label="Heure"
        v-model="time"
        :format="currentLocale === 'fr' ? '24hr' : 'ampm'"
    />
  </v-menu>
</template>

<script>
import dayjs from "dayjs";

export default {
  data() {
    return {
      time: this.value,
      menu: false,
    }
  },

  props: {
    value: {type: String},
    label: {type: String},
    prependIcon: {type: String},
    clearable: {type: Boolean},
    loading: {type: Boolean},
    rules: {type: Array},
    readonly: {type: Boolean},
    hideDetails: {type: Boolean},
  },

  model: {
    prop: 'value',
  },

  mounted() {

  },

  watch: {
    value(val) {
      this.time = val
    },

    time(val) {
      this.$emit('input', val)
      if(this.menu && val) {
        if(val) {
          this.menu = false
        }
      }
    },

  },

  computed: {

  },

  methods: {

  }
}
</script>

<style lang="scss">

</style>