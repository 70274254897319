<template>
  <div>
    <headline>
      {{ title }}
    </headline>
    <v-row>
      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            v-sortable-data-table
            :options="{
              itemsPerPage: 1000
            }"
            @sorted="saveOrder"
            disable-sort
        >
          <template #item.position>
            <v-icon class="btn-sort">mdi-sort</v-icon>
          </template>
          <template #item.name="{item}">
            {{ item.name }}
            <div class="grey--text font-weight-light">
              <i>{{ localizedString(item.label) }}</i>
            </div>
          </template>
          <template #item.description="{value}">
            {{ localizedString(value) }}
          </template>
          <template #item.filters="{value}">
            <template v-for="item in cleanedFilters(value)">
              <v-chip small outlined :key="item.value"  class="my-1 mr-2">
                {{ item.text }}
              </v-chip>
            </template>
<!--            <v-tooltip bottom v-if="subscriptionFilters?.length > 0">-->
<!--              <template v-slot:activator="{ on, attrs }">-->
<!--                <span v-bind="attrs" v-on="on">(+{{ subscriptionFilters.length }})</span>-->
<!--              </template>-->
<!--              <span>-->
<!--                <template v-for="(item, i) in subscriptionFilters">-->
<!--                  <template v-if="i > 0">, </template>-->
<!--                  {{ $t('enums.subscription-plan-filters.' + item) }}-->
<!--                </template>-->
<!--              </span>-->
<!--            </v-tooltip>-->
          </template>
          <template #item.properties="{value}">
            <template v-for="item in cleanedProperties(value)" >
              <v-chip  small outlined color="blue" :key="item.value"  class="my-1 mr-2">
                {{ item.text }}
              </v-chip>
            </template>
<!--            <v-tooltip bottom v-if="subscriptionProperties?.length > 0">-->
<!--              <template v-slot:activator="{ on, attrs }">-->
<!--                <span v-bind="attrs" v-on="on">(+{{ subscriptionProperties.length }})</span>-->
<!--              </template>-->
<!--              <span>-->
<!--                <template v-for="(item, i) in subscriptionProperties">-->
<!--                  <template v-if="i > 0">, </template>-->
<!--                  {{ $t('enums.subscription-plan-properties.' + item) }}-->
<!--                </template>-->
<!--              </span>-->
<!--            </v-tooltip>-->
          </template>
          <template #item.price="{value}">
            {{ numberFormat(value) }}  €
          </template>
          <template #item.options="{index}">
            <v-btn small icon @click="editItem(index)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn small @click="createItem">
          <v-icon left>mdi-plus</v-icon>
          {{ addLabel ?? $t('add') }}
        </v-btn>
      </v-col>
    </v-row>


    <v-dialog max-width="80%" v-if="selectedItem" v-model="showForm">
      <v-form ref="form" @submit.prevent="save">
        <v-card>
          <v-card-title>
            {{ isEdit ? editLabel : addLabel}}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-row class="subscription-item">
                  <v-col cols="12">
                    <form-field
                        :label="$t('name')"
                        v-model="selectedItem.name"
                        :rules="[commonRules.required]"
                        :col-field="9"
                    />
                    <form-field
                        type="localized-string"
                        :label="$t('label')"
                        v-model="selectedItem.label"
                        :col-field="9"
                    />
                    <form-field
                        type="localized-string"
                        :label="$t('description')"
                        v-model="selectedItem.description"
                        :col-field="9"
                    />
                    <form-field
                        input-type="number"
                        :label="$t('price-ht')"
                        v-model.number="selectedItem.price"
                        :col-field="9"
                        :rules="[commonRules.required]"
                    >
                      <template #append>
                        €
                      </template>
                    </form-field>
                    <form-field
                        v-if="hasFilters"
                        type="select"
                        :label="$t('filters')"
                        v-model="selectedItem.filters"
                        :items="filters"
                        chips
                        deletable-chips
                        :col-field="9"
                        multiple
                    />
                    <form-field
                        type="select"
                        :label="$t('properties')"
                        v-model="selectedItem.properties"
                        :items="properties"
                        :col-field="9"
                        multiple
                        chips
                        deletable-chips
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row class="ma-0">
              <v-col>
                <v-btn small text @click="deleteItem" color="grey darken-2">
                  <v-icon left>mdi-delete</v-icon>
                  {{ $t('delete') }}
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="text-right">
                <v-btn small @click="showForm = false">
                  <v-icon left>mdi-close</v-icon>
                  {{ $t('cancel') }}
                </v-btn>
                <v-btn small type="submit" color="primary" class="ml-3">
                  <v-icon left>mdi-check</v-icon>
                  {{ $t('validate') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import {v4 as uuidv4} from "uuid";
import RulesMixin from "@mixins/RulesMixin";

export default {
  data() {
    return {
      isEdit: false,
      selectedItem: null,
      showForm: false,
      items: this.value ?? [],
    };
  },


  props: {
    value: Array,
    title: String,
    subscriptionProperties: Array,
    subscriptionFilters: Array,
    properties: Array,
    filters: Array,
    sortable: Boolean,
    addLabel: String,
    editLabel: String,
  },

  mixins: [RulesMixin],

  watch: {
    value: {
      handler: function (value) {
        this.items = value
      },
      deep: true,
    },

    // 'selectedItem.properties': function(properties, oldProperties){
    //   if(oldProperties?.includes('expense-report-docs') && !properties.includes('expense-report-docs')) {
    //     properties.splice(properties.indexOf('refund-handling'), 1)
    //   }
    //   if(properties.includes('refund-handling') && !properties.includes('expense-report-docs')) {
    //     properties.push('expense-report-docs')
    //   }
    // },

    items: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true,
    },
  },

  computed: {
    headers() {
      const headers = [
        { text: this.$t('name'), value: 'name' , width: 400, align: 'left'},
        { text: this.$t('properties'), value: 'properties'},
        { text: this.$t('price-ht'), value: 'price' , width: 100 },
        { value: 'options', sortable: false , width: 100 },
      ]
      if(this.sortable && this.items?.length > 1) {
        headers.splice(0, 0, { value: 'position' })
      }
      if(this.hasFilters) {
        headers.splice(2, 0, { text: this.$t('filters'), value: 'filters'})
      }

      return headers
    },
    hasFilters() {
      return this.filters?.length > 0
    },
  },

  methods: {
    editItem(index) {
      this.isEdit = true
      this.showForm = true
      this.selectedItem = {
        index,
        ...this.items[index],
      }
    },

    createItem() {
      this.isEdit = false
      this.showForm = true
      this.selectedItem = {
        index: this.items?.length ?? 0,
        id: uuidv4(),
        price: 0,
      }
      this.$refs.form.reset()
      if(this.hasFilters) {
        this.selectedItem.filters = []
      }
    },

    save() {
      if(this.$refs.form.validate()) {
        const item = {
          id: this.selectedItem?.id,
          name: this.selectedItem?.name,
          label: this.selectedItem?.label,
          description: this.selectedItem?.description,
          price: this.selectedItem?.price,
          properties: this.selectedItem?.properties,
        }
        if(this.hasFilters) {
          item.filters = this.selectedItem.filters
        }

        this.$set(this.items, this.selectedItem.index, item)

        this.showForm = false
      }
    },

    deleteItem() {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.items.splice(this.selectedItem.index, 1)
          this.selectedItem = null
          this.showForm = false
        }
      })
    },

    saveOrder (event) {
      const movedItem = this.items.splice(event.oldIndex, 1)[0];
      this.items.splice(event.newIndex, 0, movedItem);
    },

    cleanedProperties(properties) {
      const items = this.cloneObj(properties)  ?? []
      // if(this.subscriptionProperties?.length > 0) {
      //   items.push(...this.subscriptionProperties)
      // }
      return this.cleanedItems(items, 'properties')
    },

    cleanedFilters(filters) {
      const items = this.cloneObj(filters) ?? []
      // if(this.subscriptionFilters?.length > 0) {
      //   items.push(...this.subscriptionFilters)
      // }
      return this.cleanedItems(items, 'filters')
    },

    cleanedItems(items, type) {
      return items.map(p => ({
        value: p,
        text: this.$t('enums.subscription-plan-' + type + '.' + p),
      })).toSorted((a, b) => {
        if ( a.text < b.text ){
          return -1;
        }
        if ( a.text > b.text ){
          return 1;
        }
        return 0;
      })
    },
  }
}
</script>

<style lang="scss">

</style>
