import Vue from 'vue'

class SubscriptionRepository {

  search(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/subscriptions/search',
        method: 'post',
        data,
      }).then(response => {
        resolve(response.data)
      }).catch(e => {
        reject(e)
      })
    })
  }

  get(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/subscriptions/get/' + id,
        method: 'get',
      }).then(response => {
        resolve(response.data)
      }).catch(e => {
        reject(e)
      })
    })
  }

  update(id, data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/subscriptions/update/' + id,
        method: 'put',
        data,
      }).then(response => {
        resolve(response.data)
      }).catch(e => {
        reject(e)
      })
    })
  }


  organizationSubscribe(id, data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/organization/${id}/subscribe`,
        method: 'POST',
        data,
      }).then(response => {
        resolve(response.data)
      }).catch(e => {
        reject(e)
      })
    })
  }


  searchPlans(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/subscriptions/plans/search',
        method: 'post',
        data,
      }).then(response => {
        resolve(response.data)
      }).catch(e => {
        reject(e)
      })
    })
  }

  getPlan(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/subscriptions/plans/get/' + id,
        method: 'get',
      }).then(response => {
        resolve(response.data)
      }).catch(e => {
        reject(e)
      })
    })
  }

  createPlan(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/subscriptions/plans/create',
        method: 'post',
        data,
      }).then(response => {
        resolve(response.data)
      }).catch(e => {
        reject(e)
      })
    })
  }

  updatePlan(id, data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/subscriptions/plans/update/' + id,
        method: 'put',
        data,
      }).then(response => {
        resolve(response.data)
      }).catch(e => {
        reject(e)
      })
    })
  }

  planStruct() {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/subscriptions/plans/struct',
        method: 'get',
      }).then(response => {
        resolve(response.data)
      }).catch(e => {
        reject(e)
      })
    })
  }


  delete(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/subscriptions/${id}`,
        method: 'delete',
      })
          .then(response => {
            resolve(response.data)
          }).catch(err => {
        reject(err)
      })
    })
  }

  searchLicences(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/subscriptions/licences/search`,
        method: 'post',
        data
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  countLicences(subscriptionId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/subscriptions/licences/count`,
        method: 'post',
        data: {
          subscriptionId
        }
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getLicence(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/subscriptions/licences/get/${id}`,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  registerLicence(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/subscriptions/licences/register`,
        method: 'post',
        data
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  updateLicence(id, data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/subscriptions/licences/update/${id}`,
        method: 'post',
        data
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  unregisterLicence(id, effectiveDate) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/subscriptions/licences/unregister/${id}`,
        method: 'post',
        data: {
          effectiveDate
        }
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  reactivateLicence(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/subscriptions/licences/reactivate/${id}`,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  struct(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/subscriptions/struct`,
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default new SubscriptionRepository()