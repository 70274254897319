<template>

  <v-hover v-model="masterHover">
    <v-navigation-drawer
      app
      dark
      :right="right"
      color="primary"
      class="white--text"
      width="440"
      mini-variant-width="122"
      :mini-variant="!hover || right"
      mobile-breakpoint="620"
      v-click-outside="clickOutside"
    >


      <template v-if="!right">

        <div class="top-link-menu halo-effect">

          <div class="nav-title">
            <div class="content hide-on-mini">
              ZE
            </div>
          </div>
          <div class="icon">
            <div class="btn-burger" :class="{active: hover}" @click="hover = !hover">
              <div></div>
              <div></div>
            </div>
          </div>

          <div class="right hide-on-mini">
            <v-btn icon @click.prevent="switchLocale('fr')" v-if="currentLocale !== 'fr'">
              <v-avatar size="26">
                <img src="@images/flag-fr.png" />
              </v-avatar>
            </v-btn>
            <v-btn icon @click.prevent="switchLocale('en')" v-if="currentLocale !== 'en'">
              <v-avatar size="26">
                <img src="@images/flag-en.png" />
              </v-avatar>
            </v-btn>
            <v-btn icon @click.prevent="switchLocale('nl')" v-if="currentLocale !== 'nl'">
              <v-avatar size="26">
                <img src="@images/flag-nl.png" />
              </v-avatar>
            </v-btn>

          </div>
        </div>


        <v-list>

          <v-list-item v-if="false" to="/settings/organization" class="vue-globale">
            <v-list-item-icon>
              <div class="icon">
                <v-icon>ico-world</v-icon>
              </div>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('menu.global-view') }}</v-list-item-title>
              <v-list-item-subtitle v-html="$t('menu.global-hint')"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>



          <v-list-item to="/dashboard">
            <v-list-item-icon>
              <v-icon>ico-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('menu.dashboard') }}</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <template  v-if="currentUser.permissions.includes('ORGANIZATION_WRITE')">
            <v-list-item :to="{name: 'organizations.search'}">
              <v-list-item-icon>
                <v-icon size="40">ico-organization-menu</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('organization.organizations') }}</v-list-item-title>
            </v-list-item>
          </template>

          <v-divider></v-divider>

          <template v-if="currentUser.permissions.includes('STATION_READ')">
            <v-list-item :to="{name: 'charging-points'}" v-if="!hover">
              <v-list-item-icon>
                <v-icon>ico-menu-charging-point</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('menu.charging-points') }}</v-list-item-title>
            </v-list-item>


            <v-list-group
                v-else
                :value="false"
                prepend-icon="ico-menu-charging-point"
                no-action
                color="white"
                @click="hover=true"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('menu.charging-points') }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-divider></v-divider>


              <v-list-item dense :to="{name: 'charging-points'}">
                <v-list-item-icon>
                  <v-icon>ico-magnify</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('search') }}</v-list-item-title>
              </v-list-item>


              <template  v-if="currentUser.permissions.includes('STATION_ADD')">
                <v-divider></v-divider>

                <v-list-item dense :to="{name: 'charging-points.add'}">
                  <v-list-item-icon>
                    <v-icon>ico-plus</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t('add') }}</v-list-item-title>
                </v-list-item>
              </template>

              <template v-if="isDeveloper">
                <v-divider></v-divider>
                <v-list-item  dense :to="{name: 'locations'}">
                  <v-list-item-icon>
                    <v-icon>mdi-counter</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $tc('location.location', 2) }}</v-list-item-title>
                </v-list-item>

              </template>

              <template v-if="currentUser.isZE">
                <v-divider></v-divider>
                <v-list-item  dense :to="{name: 'meter-device'}">
                  <v-list-item-icon>
                    <v-icon>mdi-counter</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $tc('meter-device.meter-device', 2) }}</v-list-item-title>
                </v-list-item>

              </template>

              <template v-if="isDeveloper">
                <v-divider></v-divider>
                <v-list-item dense :to="{name: 'meter-device.import-cdrs'}">
                  <v-list-item-icon>
                    <v-icon>mdi-microsoft-excel</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Import CDR Meter devices</v-list-item-title>
                </v-list-item>
              </template>
            </v-list-group>

          </template>

          <v-divider></v-divider>

          <template v-if="currentUser.permissions.includes('CUSTOMER_READ')">
            <v-list-item :to="{name: 'customers'}" v-if="!hover">
              <v-list-item-icon>
                <v-icon>ico-carplug</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('menu.customers') }}</v-list-item-title>
            </v-list-item>

            <v-list-group
                v-else
                :value="false"
                prepend-icon="ico-carplug"
                no-action
                color="white"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('menu.customers') }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-divider></v-divider>


              <v-list-item dense :to="{name: 'customers'}">
                <v-list-item-icon>
                  <v-icon>ico-magnify</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('search') }}</v-list-item-title>
              </v-list-item>


              <template v-if="currentUser.permissions.includes('CUSTOMER_WRITE')">
                <v-divider></v-divider>
                <v-list-item dense :to="{name: 'customer.add'}">
                  <v-list-item-icon>
                    <v-icon>ico-plus</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t('add') }}</v-list-item-title>
                </v-list-item>
              </template>

            </v-list-group>
          </template>

          <v-divider></v-divider>

          <template  v-if="currentUser.permissions.includes('OPERATOR_READ')">
            <v-list-item :to="{name: 'token.search'}">
              <v-list-item-icon>
                <v-icon size="40">ico-rfid</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $tc('member.token', 2) }}</v-list-item-title>
            </v-list-item>
          </template>

          <v-divider></v-divider>




          <template v-if="currentUser.permissions.filter(p => p.substring(0, 7) === 'REPORT_').length > 0">
            <v-list-item :to="{name: 'reports'}">
              <v-list-item-icon>
                <v-icon>mdi-file-chart</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('menu.reports') }}</v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>
          </template>

          <template v-if="currentUser.permissions.includes('ISSUE_READ')">
            <v-list-item :to="{name: 'issues'}">
              <v-list-item-icon>
                <v-icon>mdi-alert</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('menu.issues') }}</v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>
          </template>


            <v-list-group
                :value="false"
                prepend-icon="mdi-cogs"
                no-action
                class="settings"
                color="white"
                @click="hover=true"
                v-if="currentUser.permissions.includes('ORGANIZATION_WRITE') || currentUser.permissions.includes('USER_WRITE') || currentUser.permissions.includes('OPERATOR_WRITE')"
            >
              <template v-slot:activator>

                  <v-list-item-content>
                    <v-list-item-title>{{ $t('settings') }}</v-list-item-title>
                  </v-list-item-content>
              </template>

              <template  v-if="currentUser.permissions.includes('OPERATOR_WRITE')">
                <v-list-item dense :to="{name: 'operators'}">
                  <v-list-item-title>{{ $tc('operator.operator') }}</v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>
              </template>




              <template  v-if="currentUser.permissions.includes('OPERATOR_WRITE') && currentUser.isZE">
                <v-list-item dense :to="{name: 'subscription-plans'}">
                  <v-list-item-title>{{ $tc('subscription.subscription-plan', 2) }}</v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>


                <v-list-item dense :to="{name: 'operator-groups'}">
                  <v-list-item-title>{{ $tc('operator-group.operator-group', 2) }}</v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item dense :to="{name: 'agreements'}">
                  <v-list-item-title>{{ $tc('agreement.agreement', 2) }}</v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item dense :to="{name: 'vat-rates'}">
                  <v-list-item-title>{{ $tc('vat-rate.vat-rate', 2) }}</v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item dense :to="{name: 'price-index'}">
                  <v-list-item-title>{{ $tc('price-index.price-index', 2) }}</v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item dense :to="{name: 'electricity-index'}">
                  <v-list-item-title>{{ $tc('electricity-index.electricity-index', 2) }}</v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item dense :to="{name: 'gireve.import-tariffs'}">
                  <v-list-item-title>{{ $t('gireve.import-tariffs') }}</v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item dense  :to="{name: 'anomaly.subscription'}">
                  <v-list-item-title>{{ $t('anomaly.subscription-anomalies') }}</v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item dense  :to="{name: 'charging-point-types'}">
                  <v-list-item-title>{{ $t('charging-point-type.charging-point-type') }}</v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>

              </template>

              <template  v-if="currentUser.permissions.includes('USER_WRITE')">
                <v-list-item dense :to="{name: 'user.search'}">
                  <v-list-item-title>{{ $t('user.users') }}</v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>
              </template>

              <v-list-item disabled dense v-if="false">
                <v-list-item-title>Station types</v-list-item-title>
              </v-list-item>


            </v-list-group>

        </v-list>

        <v-spacer></v-spacer>

        <div class="halo-effect mt-10"></div>

        <v-hover v-model="hover">
          <div class="bottom-link-menu" >
            <div class="left" @click="$router.push({name: 'my-account'})">
              <div class="avatar">
                <v-avatar width="70" height="70" color="white">
                  <span class="text-h5" style="color: #e53c22">{{ userInitial }}</span>
                </v-avatar>
                <v-icon>ico-settings</v-icon>
              </div>
            </div>
            <div class="content hide-on-mini"  @click="$router.push({name: 'my-account'})">
              <div class="nav-title">{{ $t('menu.my-account') }}</div>
              <div>{{ $t('menu.my-account-hint') }}</div>
            </div>
            <div class="right hide-on-mini">
              <v-btn icon x-large @click.prevent="$auth.logout()">
                <v-icon>ico-logout</v-icon>
              </v-btn>
            </div>
          </div>
        </v-hover>
      </template>
      <template v-else>
        <v-spacer></v-spacer>

        <div class="halo-effect"></div>
        <v-btn color="#E53C22" class="back-btn" to="/dashboard">
          <v-icon>ico-right-arrow</v-icon>
        </v-btn>
        <div class="halo-effect reverse"></div>
        <v-spacer></v-spacer>
      </template>

    </v-navigation-drawer>
  </v-hover>
</template>

<script>
export default {
  name: "LeftNav",
  data() {
    return {
      masterHover: false,
      hover: false,
      hoverOn: true,
    }
  },

  watch: {
    masterHover(val) {
      if(!val) {
       // this.hover = false
      }
    },
    '$route.path': function(val, old) {
      //if(val === '/dashboard')
      {

        this.hover = false
      }
    }
  },

  computed: {
    right() {
      return this.$route.path.substr(0, 9) === '/settings'
    },

    userInitial() {
      let initials = '';
      initials += this.currentUser.firstname.substr(0, 1)
      initials += this.currentUser.lastname.substr(0, 1)

      return initials
    },
  },

  methods: {
    clickOutside() {
      this.hover = false
    },

  }
}
</script>

<style lang="scss">

$icon-size-level0: 50px;
$icon-size-level1: 20px;

.v-navigation-drawer {

  &.v-navigation-drawer--mini-variant {
    .hide-on-mini {
      display: none !important;
      opacity: 0;
    }
    .v-list {
      .v-list-item {
        justify-content: unset;
        .v-list-item__icon {
          margin-right: 0 !important;
        }
      }

    }
  }
  .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;


    .top-link-menu {
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: center;
      min-height: 80px !important;
      justify-content: space-between;
      padding-right: 16px;
      .nav-title {
        position: absolute;
        text-align: center;
        width: 100%;
        font-family: "Fredoka One", sans-serif;
        font-size: 34px;
        .content {
          margin: 0 122px;
        }
      }
      .icon {
        width: 80px;
        height: 80px;
        margin-left: 50px;
        display: flex;
        align-items: center;
        .btn-burger {
          position: relative;
          overflow: hidden;
          width: 22px;
          height: 30px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;

          div {
            width: 100%;
            height: 1px;
            background-color: #fff;
            transition: all .1s linear;
          }

          div + div {
            margin-top: 5px;
          }

          &:before, &:after {
            position: absolute;
            content: '';
            height: 1px;
            background-color: #fff;
            width: 100%;
            transition: all .1s linear;
          }
          &:before{
            top: 5px;
          }
          &:after {
            bottom: 5px;
          }

          &.active {
            div {
              transform: rotateZ(45deg);
            }
            div + div {
              transform: translateY(-1px)  rotateZ(-45deg);
              margin-top: 0;
            }
            &:before{
              top: 0;
              opacity: 0;
            }
            &:after {
              bottom: 0;
              opacity: 0;
            }
          }
        }
      }
      .right {
        overflow: hidden;
        animation: fadeIn ease .8s;
        max-height: 60px;
        .v-avatar {
          border: 1px solid white;
        }
      }
      &:after {
        content: "";
        position: absolute;
        bottom: -31px;
        left: 0;
        width: 100%;
        height: 60px;
        min-height: 0 !important;
        background: transparent radial-gradient(closest-side at 50% 50%, #FFFFFF 0%, #FFFFFF00 100%) 0 0 no-repeat padding-box;
        mix-blend-mode: overlay;
        opacity: 0.66;
      }
    }

    .halo-effect {
      position: relative;
      min-height: 30px;
      overflow: hidden;
      &:after {
        content: "";
        position: absolute;
        bottom: -31px;
        left: 0;
        width: 100%;
        height: 60px;
        min-height: 0 !important;
        background: transparent radial-gradient(closest-side at 50% 50%, #FFFFFF 0%, #FFFFFF00 100%) 0 0 no-repeat padding-box;
        mix-blend-mode: overlay;
        opacity: 0.66;
      }
      &.reverse {
        &:after {
          top: -31px;
        }
      }
    }

    .bottom-link-menu {
      box-shadow: 2px 2px 10px 0px #222222;
      background-color: #E53C22;
      padding: 16px;
      position: relative;
      display: flex;
      z-index: 1;
      align-content: center;
      .left {
        width: 80px;
        display: flex;
        margin: 0 20px 0 10px;
        cursor: pointer;
        .avatar {
          position: relative;
          width: 70px;
          height: 70px;

          .v-avatar {
            border: 2px solid white;
          }

          .v-icon {
            position: absolute;
            border: 2px solid #E53C22;
            color: #E53C22;
            background-color: #FFF;
            bottom: 0;
            right: 0;
            border-radius: 50%;
            width: 25px;
            height: 25px;
          }
        }
      }
      .content {
        overflow: hidden;
        height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        white-space: nowrap;
        cursor: pointer;
        flex: 1;
        .nav-title {
          text-transform: uppercase;
          font-family: "Fredoka One", sans-serif;
          font-size: 16px;
        }
      }
      .right {
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .v-list {
      padding-top: 0;
      .v-list-item {
        position: relative;
        .v-list-item__icon {
          width: 90px;
          min-width: 90px !important;
          height: 70px;
          margin: 0 20px 0 0;
          align-items: center;
          justify-content: center;
          .v-icon {
            font-size: $icon-size-level0;
          }
        }
        .v-list-item__title {
          text-transform: uppercase;
          font-family: "Fredoka One", sans-serif;
          font-size: 16px;
          flex: unset;
        }
        &.vue-globale {
          background: #00000038 0% 0% no-repeat padding-box;
          .v-list-item__icon {
            margin-top: 16px !important;
            margin-bottom: 16px !important;
            .icon {
              border: 1px solid white;
              border-radius: 10px;
              width: 80px;
              height: 80px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .v-list-item__subtitle {
            white-space: unset;
          }
        }
      }
      .v-list-group {
        &.settings {
          &.v-list-group--no-action > .v-list-group__items > .v-list-item {
            padding-left: 40px;
          }
          .v-list-group__items {
            padding-left: 100px;
          }
        }
        &.v-list-group--no-action > .v-list-group__items > .v-list-item {
          padding-left: 16px;
        }
        .v-list-item__icon {

        }
        .v-list-group__items {
          .v-list-item {
            .v-list-item__title {
              font-family: Dosis, sans-serif;
            }
            .v-list-item__icon {
              position: relative;
              width: 40px;
              padding-left: 40px;
              .v-icon {
                font-size: $icon-size-level1;
              }
            }
            &:hover {
              .v-list-item__icon {
                .v-icon {
                  color: #E53C22;
                  z-index: 1;
                }
                &:after {
                  content: '';
                  position: absolute;
                  width: 40px;
                  height: 40px;
                  border-radius: 5px;
                  background-color: white;
                  z-index: 0;
                }
              }
            }
          }
        }
      }
      .v-divider {
        margin: 0 40px;
        background-color: #fff;
        opacity: .33;
      }
    }

    .back-btn {
      height: 80px !important;
      border-radius: 0;
    }

    @keyframes fadeIn{
      0% {
        opacity:0;
      }
      100% {
        opacity:1;
      }
    }

    @-moz-keyframes fadeIn {
      0% {
        opacity:0;
      }
      100% {
        opacity:1;
      }
    }

    @-webkit-keyframes fadeIn {
      0% {
        opacity:0;
      }
      100% {
        opacity:1;
      }
    }

    @-o-keyframes fadeIn {
      0% {
        opacity:0;
      }
      100% {
        opacity:1;
      }
    }

    @-ms-keyframes fadeIn {
      0% {
        opacity:0;
      }
      100% {
        opacity:1;
      }
    }
  }
}
</style>
