<template>
  <v-chip v-if="chargingPointId" class="chip-status" :class="status.toLowerCase()" v-on="$listeners">
    <v-icon left size="16">{{ icon }}</v-icon>
    {{ label }}
  </v-chip>
</template>

<script>
import {EventBus} from "@includes/EventBus";

export default {
  data() {
    return {
      type: this.value,
    }
  },

  props: {
    value: String,
    chargingPointId: String,
    connectorIds: Array,
  },

  created() {
    this.notificationToken = null
  },

  mounted() {
    if(this.chargingPointId) {
      this.notificationToken = EventBus.$on('on-StatusNotification-' + this.chargingPointId, this.onStatusNotification)
    }
  },

  destroyed() {
    if(this.chargingPointId) {
      EventBus.$off(this.notificationToken)
    }
  },

  watch: {
    value() {
      this.type = this.value
    }
  },

  computed: {

    status() {
      return this.type || 'UNKNOWN'
    },

    label() {
      return this.$t('enums.evse-status.' + this.status)
    },

    icon() {
      if(this.status === "UNKNOWN") {
        return 'mdi-help-circle-outline'
      }
      if(this.status === "PLANNED") {
        return 'mdi-clock-outline'
      }
      return 'ico-status-' + this.status.toLowerCase()
    }
  },

  methods: {
    onStatusNotification(data) {
      if(this.connectorIds?.includes(data.message.connectorId)) {
        this.type = this.ocppStatusToStatus(data.message.connectorStatus)
      }
    }

  },

}
</script>

<style lang="scss">
.chip-status {
  vertical-align: middle;
  &.v-chip {
    background: white !important;
    font-size: 18px;
    font-weight: 600;
    height: 60px;
    border-radius: 30px;
    padding: 0 30px 0 30px;
    .v-icon {
      margin-right: 20px;
      font-size: 20px;
    }
    &.available {
      border: 2px solid #00897E !important;
      color: #00897E !important;
      box-shadow: 0 0 30px #00897E4D;
      .v-icon {
        color: #00897E !important;
      }
    }
    &.blocked {
      border: 2px solid #b3028d !important;
      color: #b3028d !important;
      box-shadow: 0 0 30px #b3028d4D;
      .v-icon {
        color: #b3028d !important;
      }
    }
    &.charging {
      border: 2px solid #DEAC3C !important;
      color: #DEAC3C !important;
      box-shadow: 0 0 30px #DEAC3C4D;
      .v-icon {
        color: #DEAC3C !important;
      }
    }
    &.offline {
      border: 2px solid #E53C22 !important;
      color: #E53C22 !important;
      box-shadow: 0 0 30px #E53C224D;
      .v-icon {
        color: #E53C22 !important;
      }
    }
    &.outoforder {
      border: 2px solid #758093 !important;
      color: #758093 !important;
      box-shadow: 0 0 30px #7580934D;
      .v-icon {
        color: #758093 !important;
      }
    }
    &.unknown {
      border: 2px solid #000000 !important;
      color: #000000 !important;
      box-shadow: 0 0 30px #0000004D;
      .v-icon {
        color: #000000 !important;
      }
    }
    &.planned {
      border: 2px solid #1586e3 !important;
      color: #1586e3 !important;
      box-shadow: 0 0 30px #1586e34D;
      .v-icon {
        color: #1586e3 !important;
      }
    }
  }
}
</style>

<!--"BLOCKED": "Blocked",-->
<!--"INOPERATIVE": "Inoperative",-->
<!--"PLANNED": "Planned",-->
<!--"REMOVED": "Removed",-->
<!--"RESERVED": "Reserved",-->