<template>
  <div class="subscriptions-plan-form pa-10" v-if="subscriptionPlan">

    <title-page icon="mdi-file-chart-outline">
      {{ $t('subscription.manage-subscription-plans') }}
    </title-page>

    <v-breadcrumbs
        :items="[
            {text: $tc('subscription.subscription-plan', 2), to: {name: 'subscription-plans'}, exact: true},
            {text: subscriptionPlan.name},
        ]"
        divider=">"
    ></v-breadcrumbs>

    <v-form ref="form" class="form-grid" @submit.prevent="sendData">

      <headline>{{ $t('organization.general-information') }}</headline>

      <template v-if="!edit">
        <v-row>
          <v-col cols="12">
            <form-field
                :label="$tc('subscription.subscription-plan-type')"
                type="select-no-ac"
                v-model="subscriptionPlan.type"
                :col-field="9"
                :items="planTypes"
                :rules="[commonRules.required]"
                placeholder="Choisissez un type d'abonnement"
            />
          </v-col>
        </v-row>
      </template>


      <template v-if="subscriptionPlan.type">

        <form-field
            v-if="edit"
            :label="$tc('subscription.subscription-plan-type')"
            :value="$t('enums.subscription-plan-types.' + subscriptionPlan.type)"
            :col-field="9"
            disabled
        />

        <v-row>
          <v-col cols="12">
            <form-field
                :label="$t('name')"
                v-model="subscriptionPlan.name"
                :rules="[commonRules.required]"
                :col-field="9"
            />
            <form-field
                type="localized-string"
                :label="$t('label')"
                v-model="subscriptionPlan.label"
                :rules="[commonRules.required]"
                placeholder="Libelle de l'abonnement (visible sur les factures et les écrans de ZMS)"
                :col-field="9"
            />
            <form-field
                type="localized-string"
                :label="$t('description')"
                v-model="subscriptionPlan.description"
                placeholder="Description de l'abonnement"
                :col-field="9"
            />

            <form-field
                :label="$t('currency')"
                type="available-select"
                input-type="currency"
                v-model="subscriptionPlan.currency"
                :col-field="9"
            />


            <form-field
                v-if="edit"
                type="switch"
                :label="$t('enable')"
                v-model="subscriptionPlan.enabled"
                :col-field="9"
            />
          </v-col>
        </v-row>

        <v-row class="form-field" v-if="filters.length > 0">
          <v-col :sm="3"  class="label">
            {{ $t('default-filters') }}
          </v-col>
          <v-col :sm="9" class="pt-2">
            <v-chip class="mr-2 mb-2" v-for="filter in cleanedItems(filters, 'filters')" :key="filter.value">
              {{ filter.text }}
            </v-chip>
          </v-col>
        </v-row>

        <v-row class="form-field" v-if="properties.length > 0">
          <v-col :sm="3"  class="label">
            {{ $t('default-properties') }}
          </v-col>
          <v-col :sm="9" class="pt-2">
            <v-chip class="mr-2 mb-2" v-for="property in cleanedItems(properties, 'properties')" :key="property.value">
              {{ property.text }}
            </v-chip>
          </v-col>
        </v-row>



        <template v-if="subscriptionPlan.customFieldsDefinition?.length > 0">
          <headline class="mt-12">
            {{ $t('settings') }}
          </headline>
          <v-row>
            <v-col>
              <custom-fields
                  v-model="subscriptionPlan.customFields"
                  :fields="subscriptionPlan.customFieldsDefinition"
                  :col-field="9"
              />
            </v-col>
          </v-row>
        </template>

        <subscription-plan-item
            class="mt-12"
            :title="$t('subscription.manage-formulas')"
            v-model="subscriptionPlan.formulas"
            :subscription-properties="properties"
            :subscription-filters="filters"
            :properties="formulaProperties"
            :filters="formulaFilters"
            :add-label="$t('subscription.add-formula')"
            :edit-label="$t('subscription.edit-formula')"
            sortable
        />


        <subscription-plan-item
            class="mt-12"
            :title="$t('subscription.manage-options')"
            v-model="subscriptionPlan.options"
            :subscription-filters="filters"
            :add-label="$t('subscription.add-option')"
            :edit-label="$t('subscription.edit-option')"
            :properties="optionProperties"
        />

        <v-row>
          <v-col class="text-center">
            <v-btn type="submit" color="primary">
              <v-icon left>mdi-content-save</v-icon>
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>

      </template>
    </v-form>

  </div>
</template>

<script>
import SubscriptionRepository from "@repository/SubscriptionRepository";
import RulesMixin from "@mixins/RulesMixin";
import CustomFields from "@blocks/CustomFields.vue";
import SubscriptionPlanItem from "@pages/SubscriptionPlan/elements/SubscriptionPlanItem.vue";

export default {

  components: {
    CustomFields,
    SubscriptionPlanItem,
  },

  data() {
    return {
      loading: false,
      subscriptionPlan: {
        currency: 'EUR',
        type: null,
        label: [],
        description: [],
        enabled: true,
      },
      struct: {},
    }
  },

  mounted() {
    this.loadStruct().then(() => {
      if (this.edit) {
        this.loadData()
      }
    })
  },

  mixins: [
    RulesMixin,
  ],

  watch: {
    'subscriptionPlan.type': function (val) {
      if (!this.edit) {
        this.subscriptionPlan.customFields = []
        this.subscriptionPlan.customFieldsDefinition = this.customFieldsDefinition ?? []
      }

      this.cleanConfig()
    },

    subscriptionPlan: {
      handler() {
        this.setUnsavedChanges()
      },
      deep: true,
    },
  },


  computed: {

    edit() {
      return !!this.id
    },

    id() {
      return this.$route.params.id ?? null
    },

    planTypes() {
      const types = []
      for (const type in this.struct) {
        types.push({
          text: this.$t(`enums.subscription-plan-types.${type}`),
          value: type,
        })
      }
      return types
    },

    properties() {
      return this.struct[this.subscriptionPlan.type]?.properties ?? []
    },

    filters() {
      return this.struct[this.subscriptionPlan.type]?.filters ?? []
    },

    formulaFilters() {
      if (this.struct[this.subscriptionPlan.type]?.formulaFilters) {
        return this.struct[this.subscriptionPlan.type].formulaFilters.map(filter => ({
          text: this.$t(`enums.subscription-plan-filters.${filter}`),
          value: filter,
        }))
      }
      return []
    },

    formulaProperties() {
      if (this.struct[this.subscriptionPlan.type]?.formulaProperties) {
        return this.struct[this.subscriptionPlan.type].formulaProperties.map(property => ({
          text: this.$t(`enums.subscription-plan-properties.${property}`),
          value: property,
        }))
      }
      return []
    },

    optionProperties() {
      if (this.struct[this.subscriptionPlan.type]?.optionProperties) {
        return this.struct[this.subscriptionPlan.type].optionProperties.map(property => ({
          text: this.$t(`enums.subscription-plan-properties.${property}`),
          value: property,
        }))
      }
      return []
    },

    customFieldsDefinition() {
      return this.struct[this.subscriptionPlan.type]?.customFieldsDefinition ?? []
    },


  },

  methods: {
    loadStruct() {
      return new Promise((resolve, reject) => {
        SubscriptionRepository.planStruct().then(response => {
          this.showLoading(false)
          this.struct = response
          resolve()
        }).catch(e => {
          this.showLoading(false)
          this.notifyError(e)
          reject()
        })
      })
    },

    loadData() {
      this.showLoading(true)
      SubscriptionRepository.getPlan(this.id).then(response => {
        this.subscriptionPlan = response
        this.$nextTick(() => {
          this.cleanUnsavedChanges()
        })
        this.cleanConfig()
        this.showLoading(false)
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message || e.message)
        this.showLoading(false)
      })
    },

    sendData() {
      if (this.$refs.form.validate()) {
        this.showLoading(true)
        if (this.edit) {
          SubscriptionRepository.updatePlan(this.id, this.subscriptionPlan).then(response => {
            this.$dialog.notify.success(this.$t('update-success'))
            this.showLoading(false)
            this.cleanUnsavedChanges()
            this.$router.push({name: 'subscription-plans'})
          }).catch(e => {
            this.$dialog.notify.error(e.response?.data?.message || e.message)
            this.showLoading(false)
          })
        } else {
          SubscriptionRepository.createPlan(this.subscriptionPlan).then(response => {
            this.$dialog.notify.success(this.$t('create-success'))
            this.showLoading(false)
            this.cleanUnsavedChanges()
            this.$router.push({name: 'subscription-plans'})
          }).catch(e => {
            this.notifyError(e)
            this.showLoading(false)
          })
        }
      } else {
        this.notifyError('rules.form-error')
      }
    },

    cleanConfig() {


      const formulaProperties = this.formulaProperties.map(p => p.value)
      const formulaFilters = this.formulaFilters.map(p => p.value)
      const optionProperties = this.optionProperties.map(p => p.value)

      let formulas = []
      for (const formula of this.subscriptionPlan.formulas ?? []) {
        formulas.push({
          ...formula,
          filters: formula.filters?.filter(f => formulaFilters.includes(f)),
          properties: formula.properties?.filter(p => formulaProperties.includes(p)),
        })
      }
      this.subscriptionPlan.formulas = formulas

      let options = []
      for (const option of this.subscriptionPlan.options ?? []) {
        options.push({
          ...option,
          properties: option.properties?.filter(p => optionProperties.includes(p)),
        })
      }
      this.subscriptionPlan.options = options
    },

    cleanedItems(items, type) {
      return items.map(p => ({
        value: p,
        text: this.$t('enums.subscription-plan-' + type + '.' + p),
      })).toSorted((a, b) => {
        if ( a.text < b.text ){
          return -1;
        }
        if ( a.text > b.text ){
          return 1;
        }
        return 0;
      })
    },

  }
}
</script>

<style lang="scss">
.subscriptions-plan-form {
  .subscription-item {
    margin: 0px;
    border: 1px solid #e0e0e0;
    padding: 10px;
    margin-bottom: 10px;
  }

  .btn-sort {
    cursor: pointer;
  }
}
</style>