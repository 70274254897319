import Vue from 'vue'
import store from '../stores/index'


class SharedRepository {
  search(query) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/shared/global/search',
        method: 'post',
        data: {
          query,
        },
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
  checkIban(iban) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/shared/iban/iban-is-valid',
        method: 'post',
        data: {
          iban
        },
      })
          .then(response => {
            resolve(response.data)
          }).catch(err => {
        reject(err)
      })
    })
  }

  checkBic(bic) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/shared/iban/bic-is-valid',
        method: 'post',
        data: {
          bic
        },
      })
          .then(response => {
            resolve(response.data)
          }).catch(err => {
        reject(err)
      })
    })
  }
}

export default new SharedRepository()