<template>
  <v-app>
    <left-nav v-if="currentUser"/>
    <v-main>
      <div class="consolidate-menu">
        <div class="inner">
          <organization-select
              clearable
              v-model="selectedOrganization"
              without-filter
          />
          <v-checkbox
              :label="$t('organization.consolidate-organization')"
              v-model="isConsolidate"
              :disabled="consolidateOrganizationDisabled"
              hide-details
          />
        </div>
      </div>


      <v-container fluid  v-if="showContent">
        <keep-alive :key="$route.fullPath" :include="cachedPages" v-if="currentUser">
          <router-view></router-view>
        </keep-alive>
      </v-container>
    </v-main>
    <Footer/>
    <v-overlay color="white" :value="loading" z-index="99999">
      <v-progress-circular
          color="primary"
          indeterminate
          size="90"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>


import LeftNav from "@blocks/LeftNav";
import Footer from "@blocks/Footer";
import {EventBus} from "@includes/EventBus";
import {mapActions, mapGetters} from "vuex";
import SecurityRepository from "@repository/SecurityRepository";
import OrganizationSelect from "@blocks/Select/OrganizationSelect.vue";


export default {
  components: {
    LeftNav,
    Footer,
    OrganizationSelect,
  },

  data: () => ({
    loading: false,
    isConsolidate: true,
    selectedOrganization: null,
    isConsolidateByUser: false,
    selectedOrganizationByUser: false,
    consolidateOrganizationDisabled: true,
    displayConsolidation:false,
    showContent:true
  }),
  watch: {
    selectedOrganization(organizationId) {
      this.consolidateOrganizationDisabled = organizationId === null
      if(this.selectedOrganizationByUser) {
        this.consolidateOrganization()
      }
    },
    isConsolidate(state) {
      if(this.isConsolidateByUser){
        this.consolidateOrganization()
      }
    }
  },
  mounted() {
    EventBus.$on('reloadView', this.reloadView)

    EventBus.$on('loading', this.showLoading)
    if (this.$auth.user()) {
      this.showLoading(true)
      this.fetchCurrentUser().then(user => {

        this.selectedOrganization = user.selectedOrganizationId;
        this.isConsolidate = user.selectedOrganizationConsolidated;

        this.$nextTick(() => {
          this.selectedOrganizationByUser = true
          this.isConsolidateByUser = true
          this.showLoading(false)
        })


      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message ?? e.message)
        this.showLoading(false)
      });
    }


  },

  destroyed() {
    EventBus.$off('loading', this.showLoading)
    EventBus.$off('reloadView', this.reloadView)
  },

  computed: {
    ...mapGetters([
      'appConfig'
    ]),


    cachedPages() {
      let pages = []

      if (this.$route.fullPath.includes('customer')) {
        pages.push('CustomerSearch')
      }

      if (this.$route.fullPath.includes('charging-point')) {
        pages.push('ChargingPointSearch')
      }

      if (this.$route.fullPath.includes('issue')) {
        pages.push('Issues')
      }

      if (this.$route.fullPath.includes('organizations')) {
        pages.push('OrganizationSearch')
      }

      return pages
    },
  },

  methods: {
    showLoading(show) {
      this.loading = show
    },

    consolidateOrganization() {
      this.showLoading(true)


      let data = {
        selectedOrganizationConsolidated: this.isConsolidate,
        selectedOrganizationId: this.selectedOrganization
      }
      SecurityRepository.consolidateOrganization(data).then(result => {
        this.reloadView()
        this.showLoading(false)
      }).catch(err => {
        this.$dialog.notify.error(err.response.data?.message || err.message)
        this.showLoading(false)
      })
    },

    reloadView() {
      this.showContent = false
      this.$nextTick(() => {
        this.showContent=true;
      });
    },

    onTreeOrganizationUpdated(options) {
      let countElems = 0
      if(options.length > 1) {
        countElems = options.length
      }  else {
        for(let i = 0; i < options.length; i++) {
          countElems++
          if(options[i].children) {
            countElems += options[i].children.length
          }
        }
      }
      this.displayConsolidation = countElems > 1
    }
  }

};
</script>

<style lang="scss">
.v-application {
  background-color: #f8f8f8 !important;

  .v-main {
    .v-main__wrap {
      & > .container {
        display: flex;
        min-width: calc(100vw - 121px);
        min-height: 100%;
        flex-direction: column;
        padding: 0 !important;
      }
    }
  }

  .consolidate-menu {
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: end;
    background-color: white;
    box-shadow: 1px 1px 5px #ccc;
    .select-organization {
      min-width: 500px;
      margin-right: 10px;
    }
    .inner {
      display: flex;
      align-items: center;
      margin-right: 30px;
      .v-input {
        padding-top: 0;
        margin-top: 0;
      }
    }
  }
}

</style>


