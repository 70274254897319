export default {




    hasClass(el, className) {
        if (el.classList)
            return el.classList.contains(className);

        if(!el.className)
            return false;

        return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
    },

    addClass(el, className) {
        if (el.classList)
            el.classList.add(className);
        else if (!this.hasClass(el, className))
            el.className += " " + className;
    },

    removeClass(el, className) {
        if (el.classList)
            el.classList.remove(className);
        else if (this.hasClass(el, className))
        {
            const reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
            el.className = el.className.replace(reg, ' ');
        }
    },

    swipeDetect(el, callback) {

        let touchsurface = el,
            swipedir,
            startX,
            startY,
            distX,
            distY,
            threshold = 150, //required min distance traveled to be considered swipe
            restraint = 100, // maximum distance allowed at the same time in perpendicular direction
            allowedTime = 300, // maximum time allowed to travel that distance
            elapsedTime,
            startTime,
            handleswipe = callback || function (swipedir) {
            };

        touchsurface.addEventListener('touchstart', function(e){
            const touchobj = e.changedTouches[0];
            swipedir = 'none';
            startX = touchobj.pageX;
            startY = touchobj.pageY;
            startTime = new Date().getTime() // record time when finger first makes contact with surface
            //e.preventDefault()
        }, false);

        // touchsurface.addEventListener('touchmove', function(e){
        //     e.preventDefault() // prevent scrolling when inside DIV
        // }, false);

        touchsurface.addEventListener('touchend', function(e){
            const touchobj = e.changedTouches[0];
            distX = touchobj.pageX - startX; // get horizontal dist traveled by finger while in contact with surface
            distY = touchobj.pageY - startY; // get vertical dist traveled by finger while in contact with surface
            elapsedTime = new Date().getTime() - startTime; // get time elapsed
            if (elapsedTime <= allowedTime){ // first condition for awipe met
                if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint){ // 2nd condition for horizontal swipe met
                    swipedir = (distX < 0)? 'left' : 'right'; // if dist traveled is negative, it indicates left swipe
                }
                else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint){ // 2nd condition for vertical swipe met
                    swipedir = (distY < 0)? 'up' : 'down'; // if dist traveled is negative, it indicates up swipe
                }
            }
            handleswipe(swipedir)
            //e.preventDefault()
        }, false)
    },

    // isEmailValid(email) {
    //     const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     return re.test(String(email).toLowerCase());
    // },
    //
    // isPhoneValid(phone) {
    //     const re = /^(?:\+?\d{1,3}\s*-?)?\(?(?:\d{3})?\)?[- ]?\d{3}[- ]?\d{4}$/m;
    //     return re.test(String(phone));
    // },


    getQuery(variable) {
        const query = window.location.search.substring(1);
        const vars = query.split("&");
        for (let i=0; i<vars.length; i++) {
            const pair = vars[i].split("=");
            if (pair[0] == variable) {
                return pair[1];
            }
        }
        return null
    },

    popup(url, name, w, h) {
        // Fixes dual-screen position ///  Most browsers      Firefox
        const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft;
        const top = (height - h) / 2 / systemZoom + dualScreenTop;

        const newWindow = window.open(url, name, 'scrollbars=yes, width=' + w / systemZoom + ', height=' + h / systemZoom + ', top=' + top + ', left=' + left);

        // Puts focus on the newWindow
        if (newWindow.focus) {
            newWindow.focus();
        }
        return newWindow
    },

    cloneObj(obj) {
        return JSON.parse(JSON.stringify(obj))
    },

    camelize(str) {
        return str
            .replace(/\s(.)/g, function($1) { return $1.toUpperCase(); })
            .replace(/\s/g, '')
            .replace(/^(.)/, function($1) { return $1.toLowerCase(); });
    },

    kebabcaseToCamelcase(str) {
        let arr = str.split('-');
        let capital = arr.map((item, index) => index ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() : item.toLowerCase());
        return capital.join("");
    },

    ucfirst(str) {
        if(typeof str !== 'string') {
            return null
        }
        return str.charAt(0).toUpperCase() + str.slice(1)
    },
    upperCamelize(str) {
        return this.ucfirst(this.camelize(str))
    },
    kebabize(label) {
        return label.normalize('NFD')
            .trim()
            // Supprime les caractères diacritiques (accents)
            .replace(/[\u0300-\u036f]/g, '')
            // Remplace les espaces et caractères non-alphanumériques (sauf '-') par des '-'
            .replace(/[^a-zA-Z0-9-]+/g, '-')
            // Convertit le texte en minuscules
            .toLowerCase()
        ;
    },
   capitaliserFirstLetter(string) {
        if(typeof string !== 'string') {
            return null
        }
        const mots = string.split(/[\s-]+/); // Divise le string en mots (espaces ou tirets)
         return mots.map(mot => mot.charAt(0).toUpperCase() + mot.slice(1)).join(" ");
    }
}
