<template>
  <v-footer absolute inset app padless
  >
    <img src="@images/logo-mini.svg" >
    <a href="https://www.zeborne.com/fr/mentions-legales/" target="_blank">{{ $t('terms') }}</a>
  </v-footer>
</template>

<script>
export default {

}
</script>

<style lang="scss">
  .v-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
    padding: 0px 20px !important;
    border-top: 1px solid black !important;
    font-size: 16px;
    img {
      margin-right: 30px;
      height: 15px;
    }
  }
</style>
